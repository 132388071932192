/* eslint-disable import/no-named-as-default */
import { Layout, Grid } from 'antd';
import { useState } from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import SideNav from '../../components/layoutComponents/sideNav';
import TopNav from '../../components/layoutComponents/topNav';
import StripeConnection from '../../containers/StripeConnection';
import MobileNav from '../../components/layoutComponents/mobileNav';
import HeaderNav from '../../components/layoutComponents/headerNav';
import NotFoundPage from '../../containers/NotFoundPage';
// import Footer from 'components/layoutComponents/Footer';
import AddProduct from '../../components/product/addProduct';
import EditProduct from '../../components/product/editProduct';
import ProductList from '../../components/product/productList';
import Orders from '../../components/product/orders';
import ShipmentListView from '../../components/shipmentListView';
import EditShipment from '../../components/shipmentListView/edit';
import UserManagementList from '../../components/user';
import AddUser from '../../components/user/addUser';
import EditUser from '../../components/user/editUser';
import Settings from '../../components/settings';
import Questionnaire from '../../components/questionnaire';
import EditQuestionnaire from '../../components/questionnaire/editQuestions';
import navigationConfig from '../../configs/NavigationConfig';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
} from '../../constants/ThemeConstant';
import utils from '../../components/utils';

const { Content } = Layout;
const { useBreakpoint } = Grid;
export const AppLayout = ({ navCollapsed, navType, location }) => {
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );
  // const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const [showSideNav, setShowSideNav] = useState(true);

  // useEffect(() => {
  //   if (!isMobile) {
  //     setShowSideNav(true);
  //   } else {
  //     setShowSideNav(false);
  //   }
  // }, [isMobile]);

  // const dispatch = useDispatch();
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };
  // const sideNavToggle = (status) => {
  //   if (isMobile) {
  //     setShowSideNav(status);
  //   }
  // };
  return (
    <Layout>
      <HeaderNav
        isMobile={isMobile}
        setShowSideNav={setShowSideNav}
        showSideNav={showSideNav}
      />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {/* {(isNavSide && !isMobile) ?   : null } */}
        {isNavSide && !isMobile ? (
          <SideNav routeInfo={currentRouteInfo} />
        ) : null}
        {/* <SideNav
          routeInfo={currentRouteInfo}
          sideNavToggle={sideNavToggle}
          isMobile={isMobile}
          showSideNav={showSideNav}
          setShowSideNav={setShowSideNav}
        /> */}

        <Layout
          className="app-layout"
          style={{ paddingLeft: getLayoutGutter() }}
        >
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            {/* <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.title}
            /> */}
            <Content>
              <Switch>
                <Route path="/" exact component={ShipmentListView} />
                <Route path="/edit-shipment/:id" component={EditShipment} />
                <Route path="/add-product" component={AddProduct} />
                <Route path="/products" component={ProductList} />
                <Route path="/questionnaire" component={Questionnaire} />
                <Route path="/edit-questions" component={EditQuestionnaire} />
                <Route path="/edit-product/:id" component={EditProduct} />
                <Route path="/orders" component={Orders} />
                <Route path="/user" component={UserManagementList} />
                <Route path="/add-user" component={AddUser} />
                <Route path="/edit-user/:id" component={EditUser} />
                <Route path="/settings" component={Settings} />
                <Route
                  path="/confirm-connection"
                  component={StripeConnection}
                />{' '}
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </Content>
          </div>
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

// export default withRouter(connect()(AppLayout));
export default withRouter(connect(mapStateToProps)(AppLayout));
