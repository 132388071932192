import { SET_SELECTED_CLUB } from './ActionTypes';
import { getItem, setItem } from '../../utils/function';

const INITIAL_STATE = {
  selectedClub: getItem('selectdClub'),
};
const clubReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_CLUB: {
      setItem('selectdClub', action.payload);
      return {
        ...state,
        selectedClub: action.payload,
      };
    }
    default:
      return state;
  }
};

export default clubReducer;
