/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { Modal, Table, Button, Input, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import firebase from 'firebase';
// import Loading from '../../sharedComponents/loading';
import AvatarStatus from '../../sharedComponents/avatarStatus';
import { editCollections } from '../../utils/function';
import Flex from '../../sharedComponents/flex';
import utils from '../utils';
import './style.scss';

export const Model = (props) => {
  const {
    userDetail,
    visible,
    setVisible,
    shipmentProductsBackup,
    shipment: _shipment,
    shipmentUsers,
  } = props;
  //   const [form] = Form.useForm();
  //   const [error] = useState('');
  const [loading] = useState(false);
  const [list, setList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [shipment, setShipment] = useState([]);
  const [item, setItem] = useState(0);
  const [productPrice, setProductPrice] = useState(0);

  useEffect(() => {
    shipmentUsers.forEach((user) => {
      if (user.id === userDetail.id) {
        setSelectedProducts(user.products ? user.products : []);
      }
    });
    setShipment(_shipment);
    setList(shipmentProductsBackup);
  }, [userDetail]);
  const updateUserOrders = () => {
    message.success(
      `${selectedProducts.length || 0} product selected successfully`
    );
    const dubShipment = [...shipmentUsers];
    dubShipment.forEach((sin) => {
      if (sin.id === userDetail.id) {
        const editableUser = { ...sin };
        editableUser.products = selectedProducts;
        editableUser.totalShippedItem += item;
        editableUser.totalProductCost =
          (editableUser.totalProductCost || 0) + productPrice;
        firebase
          .firestore()
          .collection('userShipments')
          .doc(editableUser.userShipmentId)
          .set(editableUser);
      }
    });
    const dubShip = { ...shipment };
    dubShip.totalProductsCost += productPrice;
    editCollections('shipments', dubShip);
    setVisible(false);
  };

  const revertsUserOrders = () => {
    setVisible(false);
  };

  const productExists = (productId) => {
    return selectedProducts.some(function check(el) {
      return el.id === productId;
    });
  };

  const addOrRemoveProduct = (elm) => {
    let dubProducts = [...selectedProducts];
    if (
      !productExists(elm.id) &&
      userDetail.totalItemInPlan <= item + userDetail.totalShippedItem
    ) {
      message.error(`You have reached the total limit`);
      return;
    }
    let _item = item;
    let _productPrice = productPrice;
    const dubShipment = { ...shipment };
    if (dubProducts.length === 0 || !productExists(elm.id)) {
      const product = {
        id: elm.id ? elm.id : null,
        productquantity: 1,
        productsProductPrice: elm.productPrice,
      };
      dubShipment.products.forEach((each, ind) => {
        if (elm.id === each.id) {
          dubShipment.products[ind].quantity += 1;
        }
      });
      setShipment({ ...dubShipment });
      _item += 1;
      setItem(_item);
      _productPrice += elm.productPrice;
      setProductPrice(_productPrice);
      dubProducts.push(product);
      setSelectedProducts(dubProducts);
      return;
    }

    dubProducts = dubProducts.filter((sin) => sin.id !== elm.id);
    dubShipment.products.forEach((each, index) => {
      if (elm.id === each.id) {
        dubShipment.products[index].quantity -= 1;
      }
    });
    setShipment({ ...dubShipment });
    _item -= 1;
    _productPrice -= elm.productPrice;
    setItem(_item);
    setProductPrice(_productPrice);
    setSelectedProducts(dubProducts);
  };

  const tableColumns = [
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      sorter: (a, b) => utils.antdTableSorter(a, b, 'productName'),
      render: (productName, elm) => (
        <AvatarStatus
          size={60}
          type="square"
          src={elm.productImage ? elm.productImage : ''}
          name={productName}
        />
      ),
    },
    {
      title: 'Selected',
      key: 'selected',
      render: (_, elm) => (
        <Button
          className={productExists(elm.id) ? 'bg-red w-100' : 'bg-cyan w-100'}
          onClick={() => {
            addOrRemoveProduct(elm);
          }}
        >
          {productExists(elm.id) ? 'Remove' : 'Add'}
        </Button>
      ),
    },
    // {
    //   title: '',
    //   dataIndex: 'Menu',
    //   key: 'action',
    //     render: (_, elm, ind) => (
    //       <div className="text-right">
    //         <EllipsisDropdown menu={dropdownMenu(elm, ind)} />
    //       </div>
    //     ),
    // },
  ];

  const onSearch = (e) => {
    const { value } = e.currentTarget;
    const dupShipmentProducts = [...shipmentProductsBackup];
    setList(utils.wildCardSearch(dupShipmentProducts, value));
  };

  return (
    <>
      <Modal
        className="product-list-model"
        title="shipping Items"
        visible={visible}
        okText="Ok"
        cancelButtonProps={{ style: { display: loading ? 'none' : '' } }}
        okButtonProps={{
          style: {
            display: loading || list.length === 0 ? 'none' : '',
          },
        }}
        closeIcon={
          <h3 className="item-list-header">
            {selectedProducts.length || 0} item selected
          </h3>
        }
        onOk={() => {
          updateUserOrders();
        }}
        onCancel={() => {
          revertsUserOrders();
        }}
      >
        <>
          {/* <h2>selected item 0</h2> */}
          <Flex
            className="mb-1 w-100"
            mobileFlex={false}
            justifyContent="between"
          >
            <div className="mr-md-3 mb-3">
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                onChange={(e) => onSearch(e)}
              />
            </div>
          </Flex>
          <Table
            columns={tableColumns}
            dataSource={list}
            rowKey="id"
            pagination={false}
          />
        </>
      </Modal>
    </>
  );
};
export default withRouter(Model);
