import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

export default firebase.initializeApp({
  apiKey:
    process.env.REACT_APP_FIREBASE_API_KEY ||
    'AIzaSyD-OOuNnV4_vFlus-fhLcsK-dY2LeH7BKg',
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'clinkee-app.firebaseapp.com',
  databaseURL:
    process.env.REACT_APP_FIREBASE_DATABASE_URL ||
    'https://clinkee-app.firebaseio.com',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'clinkee-app',
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'clinkee-app.appspot.com',
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '1054357625127',
  appId:
    process.env.REACT_APP_FIREBASE_APP_ID ||
    '1:1054357625127:web:76ccc5539c3761e793f102',
  measurementId:
    process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-NPB3DVW1M3',
});
