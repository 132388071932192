import { Col } from 'antd';
import CustomDomainForm from '../customDomain';
import ClubId from '../clubId';

const AddClubInfo = () => {
  return (
    <Col xs={24} sm={24} md={18} lg={18}>
      <CustomDomainForm mode="EDIT" />
      <ClubId mode="EDIT" />
    </Col>
  );
};

export default AddClubInfo;
