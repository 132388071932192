import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import firebase from 'firebase';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Tabs, Button, message, Form } from 'antd';
import { withRouter } from 'react-router-dom';
import PageHeaderAlt from '../../layoutComponents/pageHeaderAlt';
import Flex from '../../../sharedComponents/flex';
import { addCollections, editCollections } from '../../../utils/function';
import GeneralField from './generalField';
import Loading from '../../loader';

const { TabPane } = Tabs;
const ADD = 'ADD';
const EDIT = 'EDIT';

const UserForm = (props) => {
  const [form] = Form.useForm();
  const { mode: newMode, param, history } = props;
  const [mode, setMode] = useState(newMode || ADD);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(param ? param.id : null);
  const [user, setUser] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [shipment, setShipment] = useState([]);
  const [lastchargeDate, setLastChargeDate] = useState('N/A');
  const [chargeDate, setChargeDate] = useState('N/A');
  const auth = useSelector((state) => state.firebase.auth);
  const selectedClub = useSelector((state) => state.club.selectedClub);

  const [submitLoading, setSubmitLoading] = useState(false);
  useFirestoreConnect([
    {
      collection: 'userTransaction',
      where: [['userId', '==', id]],
    },
  ]);

  useEffect(() => {
    if (mode === EDIT) {
      firebase
        .firestore()
        .collection('userShipments')
        .where('id', '==', id)
        .onSnapshot((snapshot) => {
          if (snapshot.size) {
            const users = [];
            snapshot.forEach((each) => {
              const shipmentData = each.data();
              if (shipmentData) {
                users.push({ ...shipmentData });
              }
            });
            setShipment([...users]);
          }
        });
      firebase
        .firestore()
        .collection('users')
        .where('id', '==', id)
        .onSnapshot((snapshot) => {
          if (snapshot.size) {
            snapshot.forEach((each) => {
              const userData = each.data();
              if (userData) {
                setUser(userData);
                setSelectedPlan(userData.plan ? userData.plan : '');
                setChargeDate(
                  userData.chargeDate ? userData.chargeDate : 'N/A'
                );
                setLastChargeDate(
                  userData.lastChargeDate ? userData.lastChargeDate : 'N/A'
                );
                form.setFieldsValue({
                  id: userData.id,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                  phoneNumber: userData.phoneNumber,
                  plan: userData.plan,
                  userEmail: userData.userEmail,
                  address1: userData.address1,
                  address2: userData.address2,
                  city: userData.city,
                  state: userData.state,
                  zipCode: userData.zipCode,
                });
                setLoading(false);
              }
            });
          }
        });
    } else {
      setLoading(false);
    }
  }, [form, mode, param, props]);

  const onError = () => {
    setSubmitLoading(false);
    message.error('Something went wrong please try again');
  };

  const onSuccess = (values) => {
    setSubmitLoading(false);
    if (mode === ADD) {
      setId(values.id);
      const dublicateClub = { ...selectedClub };
      dublicateClub.userCount = parseInt(dublicateClub.userCount, 10) + 1;
      editCollections('clubs', dublicateClub);
      message.success(`Created ${values.firstName} to user list`);
      setMode(EDIT);
    } else if (mode === EDIT) {
      message.success(`User saved`);
    }
  };

  const onFinish = () => {
    setSubmitLoading(true);
    form
      .validateFields()
      .then((data) => {
        const values = data;
        values.adminId = auth.uid;
        values.clubId = selectedClub.id;
        values.cards = (user && user.cards) || [];
        values.plan = selectedPlan || '';
        values.chargeDate = chargeDate;
        values.lastChargeDate = lastchargeDate;
        values.address2 = values.address2 ? values.address2 : '';
        if (mode === ADD) {
          values.status = 'active';
          values.createdAt = new Date().toISOString();
          values.balance = 0;
          addCollections('users', values, onSuccess, onError);
        } else if (mode === EDIT) {
          values.id = id;
          values.status = user.status ? user.status : 'active';
          values.createdAt = user.createdAt
            ? user.createdAt
            : new Date().toISOString();
          values.balance = user.balance ? user.balance : 0;
          editCollections('users', values, onSuccess, onError);
        }
      })
      .catch(() => {
        setSubmitLoading(false);
        message.error('Please enter all required field ');
      });
  };

  return (
    <>
      <PageHeaderAlt className="bg-white border-bottom" overlap>
        <div className="container">
          <Flex
            className="py-2"
            mobileFlex={false}
            justifyContent="between"
            alignItems="center"
          >
            <h2 className="mb-3">
              {mode === 'ADD' ? 'Add New User' : `Edit User`}{' '}
            </h2>
            <div className="mb-3">
              <Button
                className="mr-2"
                onClick={() => {
                  history.push('/user');
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => onFinish()}
                disabled={submitLoading}
                loading={submitLoading}
              >
                {mode === 'ADD' ? 'Add' : `Save`}
              </Button>
            </div>
          </Flex>
        </div>
      </PageHeaderAlt>
      <div className="container">
        {loading && <Loading />}
        <Tabs defaultActiveKey="1" style={{ marginTop: 30 }}>
          <TabPane tab="General" key="1">
            <GeneralField
              user={user}
              mode={mode}
              form={form}
              selectedClub={selectedClub}
              setSelectedPlan={setSelectedPlan}
              selectedPlan={selectedPlan}
              setLastChargeDate={setLastChargeDate}
              setChargeDate={setChargeDate}
              id={id}
              shipment={shipment || []}
              setShipment={setShipment || []}
            />
          </TabPane>
          {/* <TabPane tab="Variation" key="2">
              <VariationField />
            </TabPane>
            <TabPane tab="Shipping" key="3">
              <ShippingField />
            </TabPane> */}
        </Tabs>
      </div>
    </>
  );
};

export default withRouter(UserForm);
