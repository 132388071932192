import React, { useState } from 'react';
import { Input, Alert, Form, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { MotionDiv, Button } from '@clinkee/winebar';
import firebase from '../config/firebase';

const FormItem = Form.Item;
const ForgetPass = (props) => {
  const { history } = props;
  const [form] = Form.useForm();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  // const showForgetPassword = false;
  const initialCredential = {
    email: '',
  };
  const ForgetPasswordHandler = () => {
    setError('');
    form.validateFields().then((values) => {
      const { email } = values;
      setLoading(true);
      firebase
        .resetPassword(email)
        .then(() => {
          message.success(
            'A password reset link has been sent to your email  address'
          );
          setDisable(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(err.message);
          form.setFieldsValue({
            email: '',
          });
        });
    });
  };
  // const onForgetPasswordClick = () => {};
  const rules = [
    {
      required: true,
      message: 'Please input your email',
    },
    {
      type: 'email',
      message: 'Please enter a valid email',
    },
  ];

  return (
    <div className="login-form">
      <MotionDiv showMessage={error}>
        <Alert type="error" showIcon message={error} />
      </MotionDiv>
      <Form
        layout="vertical"
        name="login-form"
        form={form}
        initialValues={initialCredential}
        onFinish={ForgetPasswordHandler}
      >
        <FormItem
          name="email"
          label="Email"
          rules={rules || ''}
          validateTrigger="onBlur"
        >
          <Input prefix={<MailOutlined className="text-primary" />} />
        </FormItem>
        <FormItem>
          <Button
            disabled={disable}
            className="gradient-background"
            htmlType="submit"
            loading={loading}
            buttonText="Send"
          />
        </FormItem>
        <FormItem>
          <Button
            className="gradient-background"
            buttonText="Sign In"
            onClick={() => {
              history.push('/login');
            }}
          />
        </FormItem>
      </Form>
    </div>
  );
};
export default withRouter(ForgetPass);
