import '../style.scss';
import { useState, useEffect } from 'react';
import { Collapse, Form, message, Button, Table, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Billing from './billing';
import ManualPayment from './manualPaymentAndAdjustment';
import { cloudFunction, currency } from '../../../utils/function';

const { Panel } = Collapse;

const PaymentManagement = (props) => {
  const { userId, user } = props;
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState();
  const [manualLoader, setManualLoader] = useState(false);
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const firestore = useSelector((state) => state.firestore);
  const [userTransaction, setUserTransaction] = useState([]);
  useEffect(() => {
    if (
      firestore.status.requested[`userTransaction?where=userId,==,${userId}`] &&
      !firestore.status.requesting[`userTransaction?where=userId,==,${userId}`]
    ) {
      setUserTransaction([...firestore.ordered.userTransaction]);
    }
  }, [firestore.status]);
  const statusColorHandler = (status) => {
    if (status === 'charge_succeeded' || status === 'charge_manually') {
      return 'cyan';
    }
    if (status === 'refund_succeeded') {
      return 'gold';
    }
    return 'red';
  };
  const refundHandler = (transId, amount) => {
    setSelectedTransaction(transId.id);
    setLoader(true);
    // if (selectedClub.connectedStripeUserId !== '') {
    const values = {
      transactionId: transId.id,
      amount,
    };
    cloudFunction('refundPayToUser', values)
      .then(() => {
        message.success('successfully refund to user');
        setLoader(false);
      })
      .catch((err) => {
        message.error(
          err && err.response && err.response.data && err.response.data.message
        );
        setLoader(false);
      });
    // } else {
    //   message.error('stripe account is not connected');
    //   setLoader(false);
    // }
  };
  const chargeHandler = () => {
    if (selectedClub.connectedStripeUserId !== '') {
      form
        .validateFields()
        .then((data) => {
          const values = data;
          values.amount = parseFloat(values.amount.substr(1));
          values.clubId = selectedClub.id;
          values.userId = userId;
          setLoading(true);
          cloudFunction('payToClub', values)
            .then(() => {
              message.success('charged successfully');
              form.resetFields();
              setLoading(false);
            })
            .catch((err) => {
              form.resetFields();
              message.error(
                err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.message
              );
              setLoading(false);
            });
        })
        .catch(() => {
          // message.error('Something went wrong');
        });
    } else {
      message.error('stripe account is not connected');
    }
  };
  const manualCharge = () => {
    form2
      .validateFields()
      .then((data) => {
        const values = data;
        values.amount = parseFloat(values.amount.substr(1));
        values.clubId = selectedClub.id;
        values.userId = userId;
        setManualLoader(true);
        cloudFunction('manualPayToClub', values)
          .then(() => {
            message.success('charged successfully');
            form2.resetFields();
            setManualLoader(false);
          })
          .catch((err) => {
            form2.resetFields();
            message.error(
              err &&
                err.response &&
                err.response.data &&
                err.response.data.message
            );
            setManualLoader(false);
          });
      })
      .catch(() => {
        message.error('Something went wrong');
      });
  };
  const tableColumns = [
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (
        <Tag
          className="text-capitalize pt-0"
          color={statusColorHandler(status)}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount) => (
        <div className="text-center">
          <NumberFormat
            displayType="text"
            value={currency(amount)}
            prefix="$"
            thousandSeparator
          />
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'chargeDate',
      render: (chargeDate) => <div>{moment(chargeDate).format('L')}</div>,
    },
    {
      title: '',
      dataIndex: 'status',
      render: (status, trans) => {
        return (
          <>
            {' '}
            {((status === 'charge_succeeded' && !trans.isRefunded) ||
              (status === 'charge_manually' && !trans.isRefunded)) && (
              <Button
                className="ml-10"
                onClick={() => refundHandler(trans, trans.amount)}
                loading={selectedTransaction === trans.id && loader}
              >
                Refund
              </Button>
            )}
          </>
        );
      },
    },
  ];
  return (
    <div className="payment-mgt">
      <h2>
        {user && user.balance ? currency(user.balance) : 0} <span>balance</span>
      </h2>
      <Collapse accordion>
        <Panel className="font-weight-bold" header="Payment Method" key="">
          <Billing userData={user} />
        </Panel>
        <ManualPayment
          header="Manual Payment"
          text=" A manual payment is used to charge the credit card that is on file"
          chargeHandler={chargeHandler}
          loading={loading}
          form={form}
        />
        <ManualPayment
          header="Manual Adjustment"
          text=" A manual adjustment will adjust the user balance but will not charge the credit card of user"
          chargeHandler={manualCharge}
          form={form2}
          loading={manualLoader}
        />

        <Panel
          className="font-weight-bold border-none"
          header="Payment History"
          key="4"
        >
          <div className="payment-history table-responsive">
            <Table
              columns={tableColumns}
              dataSource={userTransaction}
              rowKey="id"
            />
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};
export default withRouter(PaymentManagement);
