/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import { Grid, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import utils from '../../components/utils/index';

const { useBreakpoint } = Grid;

const SideContent = (props) => {
  const { sideContent, sideContentWidth = 150, border } = props;
  return (
    <div
      className={`side-content ${border ? 'with-border' : ''}`}
      style={{ width: `${sideContentWidth}px` }}
    >
      {sideContent}
    </div>
  );
};

const SideContentMobile = (props) => {
  const { sideContent, visible, onSideContentClose } = props;
  return (
    <Drawer
      width={320}
      placement="left"
      closable={false}
      onClose={onSideContentClose}
      visible={visible}
      bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
    >
      <div className="h-100">{sideContent}</div>
    </Drawer>
  );
};

const InnerAppLayout = (props) => {
  const {
    mainContent,
    pageHeader,
    visible,
    setVisible,
    sideContentGutter = true,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');

  const close = () => {
    setVisible(false);
  };

  const openSideContentMobile = () => {
    setVisible(true);
  };

  return (
    <div className="inner-app-layout">
      {isMobile ? (
        <SideContentMobile
          visible={visible}
          onSideContentClose={close}
          {...props}
        />
      ) : (
        <SideContent {...props} close={close} />
      )}
      {isMobile ? (
        <div
          className={`ml-2 main-content ${
            pageHeader ? 'has-page-header' : ''
          } ${sideContentGutter ? 'gutter' : 'no-gutter'}`}
        >
          <div
            className={`font-size-lg ${!sideContentGutter ? 'pt-3 px-3' : ''}`}
          >
            <MenuOutlined onClick={() => openSideContentMobile()} />
          </div>
          {mainContent}
        </div>
      ) : null}
    </div>
  );
};

InnerAppLayout.propTypes = {
  sideContent: PropTypes.node,
  mainContent: PropTypes.node,
  pageHeader: PropTypes.bool,
  sideContentWidth: PropTypes.number,
  border: PropTypes.bool,
  sideContentGutter: PropTypes.bool,
};

export default InnerAppLayout;
