import { useState } from 'react';
import { Form, Row, Col, Input } from 'antd';
import { useSelector } from 'react-redux';
import { Button } from '@clinkee/winebar';
import { v1 as uuid } from 'uuid';
import { MailOutlined } from '@ant-design/icons';
import RightSide from '../../components/commonComponents/rightSide';
import WineIllustration from '../../assets/svg/wine.svg';
import './style.scss';
import ClubHandler from '../ClubHandler';
import { getParams } from '../../utils/function';

const FormItem = Form.Item;
const CreateNewClub = (props) => {
  const firebase = useSelector((state) => state.firebase);
  const date = new Date();
  const [data, setData] = useState({
    adminId: [firebase.auth.uid],
    connectedStripeUserId: '',
    billingAddress: {},
    checkout: [],
    customDomain: '',
    clubName: '',
    clubLogo: '',
    clubColor: '#22194D',
    status: 'active',
    cards: [],
    createdAt: date.toISOString(),
    userCount: 0,
    lastShipmentUserCount: 0,
    currentShipmentUserCount: 0,
    sales: 0,
    lastSale: 0,
    currentSale: 0,
    revenue: 0,
    currentRevenue: 0,
    lastRevenue: 0,
    potentialSale: 0,
    clubChargeDate:
      date.getDate() === 28 ||
      date.getDate() === 29 ||
      date.getDate() === 30 ||
      date.getDate() === 31
        ? 28
        : date.getDate(),
    questions: [
      {
        id: uuid(),
        question: 'How do you take your coffee?',
        answers: [
          {
            id: uuid(),
            answer: 'Strong & black.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'Mild, but nothing in it.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'With cream and/or sugar.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: "Frappuccino'ed.",
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: "I don't.",
            totalCount: 0,
          },
        ],
        selectedAnswer: '',
        selectedAnswerId: '',
        selectedId: '',
      },
      {
        id: uuid(),
        question: 'How salty do you like your food?',
        answers: [
          {
            id: uuid(),
            answer: 'I put salt on everything.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'Like salt but don’t want to notice it.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'Like the taste but don’t seek it out.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'Take it or leave it.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: "Don't really like salty stuff.",
            totalCount: 0,
          },
        ],
        selectedAnswer: '',
        selectedAnswerId: '',
        selectedId: '',
      },
      {
        id: uuid(),
        question: 'What are your thoughts on citrus?',
        answers: [
          {
            id: uuid(),
            answer: 'Love anything mouth puckering.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'Like it, don’t love it.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'I drink the occasional glass of OJ.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'Meh.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'Nope, not at all.',
            totalCount: 0,
          },
        ],
        selectedAnswer: '',
        selectedAnswerId: '',
        selectedId: '',
      },
      {
        id: uuid(),
        question: 'Do you enjoy earthy flavors, like mushrooms?',
        answers: [
          {
            id: uuid(),
            answer: 'Yes, I’ll more or less eat dirt.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'Yeah, I like these flavors.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'In moderation.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'Not really my thing.',
            totalCount: 0,
          },
          {
            id: uuid(),
            answer: 'Gross. No.',
            totalCount: 0,
          },
        ],
        selectedAnswer: '',
        selectedAnswerId: '',
        selectedId: '',
      },
    ],
  });
  const [form] = Form.useForm();
  form.setFieldsValue({
    clubName: data.clubName,
  });
  const { history, location } = props;
  const step =
    location && location.search && location.search
      ? getParams(location.search)
      : '';
  const submitClubName = () => {
    history.push(`${window.location.pathname}?step=2`);
  };
  return (
    <>
      {step ? (
        <ClubHandler
          location={location}
          history={history}
          data={data}
          setData={setData}
        />
      ) : (
        <div className="create-new-club vh-100 bg-white">
          <Row justify="center" className="align-items-stretch h-100">
            <Col xs={20} sm={20} md={24} lg={16}>
              <div className="container d-flex flex-column justify-content-center h-100">
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={12} xl={9}>
                    <h1 className="font-weight-bold">Create Your New Club</h1>
                    <p>Let&apos;s start with a name!</p>
                    <div className="mt-4">
                      <Form
                        form={form}
                        layout="vertical"
                        name="create-new-club-form"
                        onFinish={submitClubName}
                      >
                        <FormItem
                          name="clubName"
                          label="Club Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your club name',
                            },
                          ]}
                          validateTrigger="onBlur"
                        >
                          <Input
                            prefix={<MailOutlined className="text-primary" />}
                            onChange={(event) => {
                              const dubData = { ...data };
                              dubData.clubName = event.target.value;
                              setData(dubData);
                            }}
                          />
                        </FormItem>
                        <FormItem>
                          <Button
                            className="gradient-background"
                            htmlType="submit"
                            loading={false}
                            buttonText="I Choose A Great Name"
                          />
                        </FormItem>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <RightSide illustration={WineIllustration} />
          </Row>
        </div>
      )}
    </>
  );
};
export default CreateNewClub;
