import { Row, Col } from 'antd';
import { Form, Button, FormItem } from '@clinkee/winebar';
import ColorPicker from '../../components/colorPicker';
import RightSide from '../../components/commonComponents/rightSide';
import WineIllustration from '../../assets/svg/undraw_having_fun_iais.svg';

const NewClubColorPicker = (props) => {
  const { history, data, setData } = props;
  const next = () => {
    history.push(`${window.location.pathname}?step=4`);
  };
  return (
    <div className="login-page vh-100 bg-white">
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className=" d-flex flex-column justify-content-center h-100">
            <Col>
              <Row justify="center">
                <Col xs={24} sm={24} md={20} lg={12} xl={9}>
                  <h1 className="font-weight-bold"> Create Your New Club</h1>
                  <p>Choose a color we can use for that checkout too</p>
                </Col>
              </Row>
              <Form
                layout="vertical"
                name="create-new-club-form"
                onFinish={next}
              >
                <FormItem>
                  <Row
                    justify="center"
                    style={{ backgroundColor: data.clubColor }}
                  >
                    <div className="my-4 ">
                      <ColorPicker
                        selectedColor={data}
                        setSelectedColor={setData}
                      />
                    </div>
                  </Row>
                </FormItem>
                <FormItem>
                  <Row justify="center " className="mt-2">
                    <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                      <Button
                        className="gradient-background"
                        htmlType="submit"
                        loading={false}
                        buttonText="That's my color"
                      />
                    </Col>
                  </Row>
                </FormItem>
                I
              </Form>
            </Col>
          </div>
        </Col>
        <RightSide illustration={WineIllustration} />
      </Row>
    </div>
  );
};
export default NewClubColorPicker;
