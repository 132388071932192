import { Row, Col } from 'antd';
import InvitationForm from '../../components/inviteForm';
import RightSide from '../../components/commonComponents/rightSide';
import WineIllustration from '../../assets/svg/mobile-app.svg';
import './style.scss';

const InviteSignUp = () => {
  return (
    <div className="login-page vh-100 bg-white">
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                <h1 className="font-weight-bold">Invitation</h1>
                <div className="mt-4">
                  <InvitationForm />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <RightSide illustration={WineIllustration} />
      </Row>
    </div>
  );
};

export default InviteSignUp;
