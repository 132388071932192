import { Row, Col } from 'antd';
import UploadLogo from '../../components/uploadLogoForm';
import RightSide from '../../components/commonComponents/rightSide';
import WineIllustration from '../../assets/svg/undraw_having_fun_iais.svg';

const NewClubUploadLogo = (props) => {
  const { history, data, setData } = props;
  const next = () => {
    history.push(`${window.location.pathname}?step=3`);
  };

  return (
    <div className="login-page vh-100 bg-white">
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={14} xl={10}>
                <h1 className="font-weight-bold">Create Your New Club</h1>
                <p>
                  Add a logo that we can use for branding the customer facing
                  checkout
                </p>

                <div className="mt-4">
                  <UploadLogo
                    data={data}
                    setData={setData}
                    next={next}
                    history={history}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <RightSide illustration={WineIllustration} />
      </Row>
    </div>
  );
};
export default NewClubUploadLogo;
