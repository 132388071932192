import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { notification } from 'antd';
import SignIn from '../containers/SignIn';
import { removeItem } from '../utils/function';
import SignUp from '../containers/SignUp';
import InviteSignUp from '../containers/InviteSignUp';
import PrivateRoutes from './privateRoute';
import { setSelectedClub } from '../store/actions/clubActions';
import Loader from '../components/loader';
import ForgetPassword from '../containers/ForgetPassword';

const Routes = (props) => {
  const { history, location } = props;

  const firebase = useSelector((state) => state.firebase);
  const loader = useSelector((state) => state.common.loader);
  const dispatch = useDispatch();

  // const firestore = useSelector((state) => state.firestore)
  const isPublic = (route) => {
    switch (route) {
      case '/log-in':
      case '/invite':
      case '/confirm-connection':
      case '/forget-password':
      case '/sign-up': {
        break;
      }
      default: {
        history.push('/log-in');
        break;
      }
    }
  };
  const isPrivate = (route) => {
    switch (route) {
      case '/log-in':
      case '/sign-up': {
        history.push('/');
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (firebase.auth.isLoaded) {
      if (firebase.auth.isEmpty) {
        isPublic(location.pathname);
      } else {
        isPrivate(location.pathname);
      }
    } else if (location.pathname === '/') {
      dispatch(setSelectedClub(null));
      removeItem('selectdClub');
      removeItem('userDetail');
    }
    if (location.pathname !== '/') notification.destroy();
  }, [location.pathname, firebase.auth]);

  return (
    <>
      <Switch>
        <Route
          path="/log-in"
          exact
          component={firebase.auth.isLoaded && SignIn}
        />{' '}
        <Route
          path="/forget-password"
          exact
          component={firebase.auth.isLoaded && ForgetPassword}
        />{' '}
        <Route
          path="/invite"
          exact
          component={firebase.auth.isLoaded && InviteSignUp}
        />{' '}
        <Route path="/sign-up" component={firebase.auth.isLoaded && SignUp} />{' '}
        <Route
          path="/"
          component={
            firebase.auth.isLoaded && !firebase.auth.isEmpty && PrivateRoutes
          }
        />{' '}
      </Switch>{' '}
      {(!firebase.auth.isLoaded || loader) && <Loader />}{' '}
    </>
  );
};

export default withRouter(Routes);
