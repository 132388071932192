import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FormItem, Button } from '@clinkee/winebar';
import RightSide from '../../components/commonComponents/rightSide';
import WineIllustration from '../../assets/svg/wine.svg';
import { setSelectedClub } from '../../store/actions/clubActions';
import './style.scss';

const Login = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const firestore = useSelector((state) => state.firestore);
  const selectClubHandler = (club) => {
    dispatch(setSelectedClub(club));
    history.push('/');
  };
  return (
    <div className="club-selection-page vh-100 bg-white">
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={12} xl={9}>
                <h1 className="font-weight-bold">Select Club</h1>
                <div className="mt-4">
                  {firestore.ordered.clubs &&
                    firestore.ordered.clubs.map((sin) => {
                      if (sin.status !== 'cancel') {
                        return (
                          <FormItem className="mb-2" key={sin.id}>
                            <li
                              onClick={() => selectClubHandler(sin)}
                              className="ant-input font-weight-bold"
                            >
                              {sin.clubName}
                            </li>
                          </FormItem>
                        );
                      }
                      return null;
                    })}
                  <FormItem>
                    <Button
                      className="gradient-background"
                      loading={false}
                      buttonText="Create New Club"
                      onClick={() => {
                        history.push('/create-new-club');
                      }}
                    />
                  </FormItem>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <RightSide illustration={WineIllustration} />
      </Row>
    </div>
  );
};

export default Login;
