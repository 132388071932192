import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v1 as uuid } from 'uuid';
import { Tabs, Form, Button, message } from 'antd';
import PageHeaderAlt from '../../layoutComponents/pageHeaderAlt';
import Flex from '../../../sharedComponents/flex';
import {
  addCollections,
  editCollections,
  currency,
} from '../../../utils/function';
import GeneralField from './generalField';
// import VariationField from './VariationField';
// import ShippingField from './ShippingField';

const { TabPane } = Tabs;

const ADD = 'ADD';
const EDIT = 'EDIT';

const ProductForm = (props) => {
  const { mode: newMode, param, history } = props;
  const [mode, setMode] = useState(newMode || ADD);
  const [id, setId] = useState(param && param.id);

  const firestore = useSelector((state) => state.firestore);
  const auth = useSelector((state) => state.firebase.auth);
  const selectedClub = useSelector((state) => state.club.selectedClub);

  const [form] = Form.useForm();
  const [uploadedImg, setImage] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (mode === EDIT) {
      const productData =
        firestore.ordered.products &&
        firestore.ordered.products.find((product) => product.id === id);
      if (productData) {
        form.setFieldsValue({
          id: productData.id,
          productDescription: productData.productDescription,
          productName: productData.productName,
          productPrice: currency(productData.productPrice),
        });
        setImage(productData.productImage ? productData.productImage : '');
      }
    }
  }, [form, mode, param, props]);

  const handleUploadChange = (files) => {
    const { file } = files;
    const uid = uuid();
    const storage = firebase.storage();
    const imageRef = storage.ref('productImages').child(uid);
    const imgData = imageRef.put(file);
    imgData.on(
      'state_changed',
      () => {
        setUploadLoading(true);
      },
      () => {
        setUploadLoading(false);
      },
      () => {
        imageRef.getDownloadURL().then((downloadURL) => {
          setImage(downloadURL);
          setUploadLoading(false);
        });
      }
    );
  };

  const onError = () => {
    setSubmitLoading(false);
    message.error('Something went wrong please try again');
  };

  const onSuccess = (values) => {
    setSubmitLoading(false);
    if (mode === ADD) {
      setMode(EDIT);
      setId(values.id);
      message.success(`Created ${values.productName} to product list`);
    } else if (mode === EDIT) {
      message.success(`Product saved`);
    }
  };

  const onFinish = () => {
    setSubmitLoading(true);
    form
      .validateFields()
      .then((data) => {
        const values = data;
        values.productPrice = parseFloat(values.productPrice.substr(1));
        values.productImage = uploadedImg || '';
        values.adminId = auth.uid;
        values.clubId = selectedClub.id;
        if (mode === ADD) {
          addCollections('products', values, onSuccess, onError);
        } else if (mode === EDIT) {
          values.id = id;
          editCollections('products', values, onSuccess, onError);
        }
      })
      .catch(() => {
        setSubmitLoading(false);
        message.error('Please enter all required field ');
      });
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="product_form"
        className="ant-advanced-search-form"
      >
        <PageHeaderAlt className="bg-white border-bottom" overlap>
          <div className="container">
            <Flex
              className="py-2"
              mobileFlex={false}
              justifyContent="between"
              alignItems="center"
            >
              <h2 className="mb-3">
                {mode === 'ADD' ? 'Add New Product' : `Edit Product`}{' '}
              </h2>
              <div className="mb-3">
                <Button
                  className="mr-2"
                  onClick={() => {
                    history.push('/products');
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => onFinish()}
                  disabled={uploadLoading}
                  htmlType="submit"
                  loading={submitLoading}
                >
                  {mode === 'ADD' ? 'Add' : `Save`}
                </Button>
              </div>
            </Flex>
          </div>
        </PageHeaderAlt>
        <div className="container">
          <Tabs defaultActiveKey="1" style={{ marginTop: 30 }}>
            <TabPane tab="General" key="1">
              <GeneralField
                uploadedImg={uploadedImg}
                uploadLoading={uploadLoading}
                handleUploadChange={handleUploadChange}
                form={form}
              />
            </TabPane>
            {/* <TabPane tab="Variation" key="2">
              <VariationField />
            </TabPane>
            <TabPane tab="Shipping" key="3">
              <ShippingField />
            </TabPane> */}
          </Tabs>
        </div>
      </Form>
    </>
  );
};

export default withRouter(ProductForm);
