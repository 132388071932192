/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  SIDE_NAV_WIDTH,
  // SIDE_NAV_DARK,
  NAV_TYPE_SIDE,
} from '../../constants/ThemeConstant';
import MenuContent from './menuContent';

const { Sider } = Layout;

export const SideNav = ({
  navCollapsed,
  sideNavTheme,
  routeInfo,
  hideGroupTitle,
  localization = true,
  sideNavToggle,
  isMobile,
  showSideNav,
}) => {
  const [menuTranslation, setMenuTranslation] = useState(
    isMobile ? 'translatedMenu' : ''
  );

  useEffect(() => {
    if (isMobile && showSideNav) {
      setMenuTranslation('');
    } else if (isMobile && !showSideNav) {
      setMenuTranslation('translatedMenu');
    }
    if (!isMobile) {
      setMenuTranslation('');
    }
  }, [isMobile, showSideNav]);

  const mobileMenuRef = useRef(null);

  const props = {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
  };

  return (
    <div ref={mobileMenuRef}>
      <Sider
        // className={`side-nav ${sideNavTheme === SIDE_NAV_DARK ? '' : ''}`}
        className={`side-nav ${menuTranslation}`}
        width={SIDE_NAV_WIDTH}
        collapsed={navCollapsed}
      >
        <Scrollbars autoHide>
          <MenuContent
            type={NAV_TYPE_SIDE}
            {...props}
            sideNavToggle={sideNavToggle}
          />
        </Scrollbars>
      </Sider>
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme } = theme;
  return { navCollapsed, sideNavTheme };
};

export default connect(mapStateToProps)(SideNav);
