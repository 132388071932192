import { SET_STRIPE_ACCOUNT_CONNECTION } from './ActionTypes';

const INITIAL_STATE = {
  stripeConnection: false,
};
const stripeConnection = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STRIPE_ACCOUNT_CONNECTION: {
      return {
        stripeConnection: action.payload,
      };
    }
    default:
      return state;
  }
};

export default stripeConnection;
