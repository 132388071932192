import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ReduxProvider from './store/ReduxProvider';
import Routes from './routes/routes';

import './index.scss';

ReactDOM.render(
  <ReduxProvider>
    <BrowserRouter>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById('root')
);
