import React from 'react';
// import { MenuOutlined } from '@ant-design/icons';
// import AppBreadcrumb from './appBreadcrumb';

const PageHeader = (props) => {
  const { title } = props;
  return (
    <div className="app-page-header">
      <h1 className="mb-0 mr-4 font-weight-bold">
        {' '}
        {/* {isMobile && (
          <MenuOutlined
            className="p-3"
            onClick={() =>
              settingSideNav
                ? setSettingSideNav(false)
                : setSettingSideNav(true)
            }
          />
        )} */}
        {title}
      </h1>
      {/* <AppBreadcrumb /> */}
    </div>
  );
};

export default PageHeader;
