import { Tooltip, Form, Modal, Input, Row, Col, Alert, message } from 'antd';
import {
  CreditCardOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MotionDiv } from '@clinkee/winebar';
import { ROW_GUTTER } from '../../../../../constants/ThemeConstant';
import { cloudFunction } from '../../../../../utils/function';
import Loading from '../../../../../sharedComponents/loading';

const AddNewCardForm = ({ visible, setVisible, onCancel }) => {
  const rules = {
    name: [
      {
        required: true,
        message: 'Please enter card holder name',
      },
    ],
    cardNumber: [
      {
        required: true,
        message: 'Please enter Card Number',
      },
    ],
    expiryDate: [
      {
        required: true,
        message: 'Please enter expiry Date',
      },
      {
        pattern: /^(0[1-9]|1[0-2])[- /.]\d{2}/,
        message: 'Please enter a valid date format!',
      },
    ],
    cvv: [
      {
        required: true,
        message: 'Please enter cvv code',
      },
      {
        pattern: /^[0-9]{3,4}$/,
        message: 'Please enter a CVV code format!',
      },
    ],
  };

  const selectedClub = useSelector((state) => state.club.selectedClub);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  return (
    <Modal
      title="Add new card"
      visible={visible}
      okText="Save card"
      cancelButtonProps={{ style: { display: loading ? 'none' : '' } }}
      okButtonProps={{ style: { display: loading ? 'none' : '' } }}
      onCancel={() => {
        setError('');
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        setError('');
        form.validateFields().then((values) => {
          const expiry = values.exp.split('/');
          const payLoad = {
            clubId: selectedClub.id,
            card: {
              number: values.cardNumber,
              exp_month: expiry[0],
              exp_year: expiry[1],
              cvc: values.cvv,
              name: values.cardHolderName,
            },
          };
          setLoading(true);
          cloudFunction('addCardOfClub', payLoad)
            .then(() => {
              setLoading(false);
              message.success('credit card  added successfully');
              setVisible(false);
              form.resetFields();
            })
            .catch((err) => {
              setLoading(false);
              setError(
                err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.message
              );
            });
        });
      }}
    >
      {loading ? (
        <div className=" d-flex justify-content-center h-11">
          <Loading />
        </div>
      ) : (
        <Form form={form} name="addCardForm" layout="vertical">
          <MotionDiv showMessage={!!error}>
            <Alert type="error" showIcon message={error} />
          </MotionDiv>
          <Form.Item
            label="Card holder name"
            name="cardHolderName"
            rules={rules.name}
          >
            <Input
              suffix={<CreditCardOutlined />}
              placeholder="Card holder name"
            />
          </Form.Item>
          <Form.Item
            label="Card number"
            name="cardNumber"
            hasFeedback
            rules={rules.cardNumber}
          >
            <Input
              suffix={<CreditCardOutlined />}
              placeholder="0000000000000000"
            />
          </Form.Item>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Expiry date"
                name="exp"
                rules={rules.expiryDate}
              >
                <Input suffix={<CalendarOutlined />} placeholder="MM/YY" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="CVV code" name="cvv" rules={rules.cvv}>
                <Input
                  suffix={
                    <Tooltip title="The last three digits printed on the back of the card">
                      <QuestionCircleOutlined className="cursor-pointer" />
                    </Tooltip>
                  }
                  placeholder="000"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};
export default AddNewCardForm;
