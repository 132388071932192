/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { useState } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import InnerAppLayoutt from '../../../layouts/inner-app-layout';
import navigationConfig from './settingsNavigationConfig';
import Icon from '../../utilComponents/icon';
import './style.scss';

const { SubMenu } = Menu;
const setLocale = (isLocaleOn, localeKey) => localeKey.toString();
const Setting = (props) => {
  const [visible, setVisible] = useState(false);
  const SettingOption = ({ localization, isMobile }) => {
    return (
      <div className="sideNav_setting">
        <Menu mode="inline">
          {navigationConfig.map((menu) =>
            menu.submenu.length > 0 ? (
              <Menu.ItemGroup
                key={menu.key}
                // title={setLocale(localization, menu.title)}
              >
                {menu.submenu.map((subMenuFirst) =>
                  subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      icon={
                        subMenuFirst.icon ? (
                          <Icon type={subMenuFirst?.icon} />
                        ) : null
                      }
                      key={subMenuFirst.key}
                      title={setLocale(localization, subMenuFirst.title)}
                    >
                      {subMenuFirst.submenu.map((subMenuSecond) =>
                        subMenuSecond.submenu.length > 0 ? (
                          <SubMenu
                            icon={
                              subMenuSecond.icon ? (
                                <Icon type={subMenuSecond?.icon} />
                              ) : null
                            }
                            key={subMenuSecond.key}
                            title={setLocale(localization, subMenuSecond.title)}
                          >
                            {subMenuSecond.submenu.map((subMenuThird) =>
                              subMenuThird.submenu.length > 0 ? (
                                <SubMenu
                                  icon={
                                    subMenuThird.icon ? (
                                      <Icon type={subMenuThird?.icon} />
                                    ) : null
                                  }
                                  key={subMenuThird.key}
                                  title={setLocale(
                                    localization,
                                    subMenuThird.title
                                  )}
                                >
                                  {subMenuThird.submenu.map((subMenuFourth) => (
                                    <Menu.Item key={subMenuFourth.key}>
                                      {subMenuFourth.icon ? (
                                        <Icon type={subMenuFourth?.icon} />
                                      ) : null}
                                      <span>
                                        {setLocale(
                                          localization,
                                          subMenuFourth.title
                                        )}
                                      </span>
                                      <Link to={subMenuFourth.path} />
                                    </Menu.Item>
                                  ))}
                                </SubMenu>
                              ) : (
                                <Menu.Item key={subMenuThird.key}>
                                  {subMenuThird.icon ? (
                                    <Icon type={subMenuThird?.icon} />
                                  ) : null}
                                  <span>
                                    {setLocale(
                                      localization,
                                      subMenuThird.title
                                    )}
                                  </span>
                                  <Link to={subMenuThird.path} />
                                </Menu.Item>
                              )
                            )}
                          </SubMenu>
                        ) : (
                          <Menu.Item
                            key={subMenuSecond.key}
                            onClick={() => {
                              if (isMobile) {
                                setVisible(false);
                              }
                            }}
                          >
                            {subMenuSecond.icon ? (
                              <Icon type={subMenuSecond?.icon} />
                            ) : null}
                            <span>
                              {setLocale(localization, subMenuSecond.title)}
                            </span>
                            <Link to={subMenuSecond.path} />
                          </Menu.Item>
                        )
                      )}
                    </SubMenu>
                  ) : (
                    <Menu.Item key={subMenuFirst.key}>
                      {subMenuFirst.icon ? (
                        <Icon type={subMenuFirst.icon} />
                      ) : null}
                      <span>{setLocale(localization, subMenuFirst.title)}</span>
                      <Link to={subMenuFirst.path} />
                    </Menu.Item>
                  )
                )}
              </Menu.ItemGroup>
            ) : (
              <Menu.Item key={menu.key}>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu?.title)}</span>
                {menu.path ? <Link to={menu.path} /> : null}
              </Menu.Item>
            )
          )}
        </Menu>
      </div>
    );
  };
  return (
    <InnerAppLayoutt
      sideContentWidth={window.screen.width - '0%'}
      sideContent={<SettingOption {...props} />}
      visible={visible}
      setVisible={setVisible}
    />
  );
};

export default Setting;
