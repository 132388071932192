import React from 'react';
import './style.scss';

const Loader = () => (
  <div className="mainWrapper">
    <div className="loader-container">
      <div className="loader" />
    </div>
  </div>
);
export default Loader;
