import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Table,
  Menu,
  Tooltip,
  Progress,
  Modal,
  message,
  notification,
  Button,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import NumberFormat from 'react-number-format';
import {
  DeleteOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import StatisticWidget from '../../sharedComponents/statisticWidget';
import EllipsisDropdown from '../../sharedComponents/ellipsisDropdown';
import NewShipmentModel from '../newShipmentmodel';
import Flex from '../../sharedComponents/flex';
import {
  cloudFunction,
  currency,
  getCollectionsById,
  calculatePercentage,
} from '../../utils/function';
import utils from '../utils';
import Loading from '../loader';

import { setClubPlan } from '../../store/actions/culbPlanAction';
import { setStripeAccountConnection } from '../../store/actions/stripeAccountConnection';

import './style.scss';

const { confirm } = Modal;

const ShipmentList = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const firestore = useSelector((state) => state.firestore);
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const clubPlan = useSelector((state) => state.plan.planExist);
  const [timePeriod, setTimePeriod] = useState('weekly');
  const stripeConnection = useSelector(
    (state) => state.stripeConnection.stripeConnection
  );
  const [shipmentListData, setShipmentListData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  let status = true;
  const shipmentCollection = `shipments?where=clubId,==,${selectedClub.id}`;
  const [weeklyUserCount, setWeeklyUserCount] = useState({
    actualValues: [],
    calculatedValue: 0,
  });
  const [weeklyUserRevenue, setWeeklyUserRevenue] = useState({
    actualValues: [],
    calculatedValue: 0,
  });
  const [weeklyUserSales, setWeeklyUserSales] = useState({
    actualValues: [],
    calculatedValue: 0,
  });

  useFirestoreConnect([
    {
      collection: 'shipments',
      where: [['clubId', '==', selectedClub.id]],
    },
  ]);

  const actionHandler = () => {
    notification.close('SetUpPlans');
    dispatch(setClubPlan(true));
    history.push('/settings/checkout');
  };
  const paymentActionHandler = () => {
    notification.close('SetUpPayment');
    dispatch(setStripeAccountConnection(true));
    history.push('/settings/payment-provider');
  };
  const closePaymentNotifications = () => {
    notification.close('SetUpPayment');
    dispatch(setStripeAccountConnection(true));
  };
  const closePlanNotifications = () => {
    notification.close('SetUpPlans');
    dispatch(setClubPlan(true));
    if (!selectedClub.connectedStripeUserId && !stripeConnection) {
      notification.open({
        message: 'Add Your Payment Details',
        description:
          'Link your Clinkee account to stripe to begin automatically charging customers for their membership. ',
        btn: (
          <Button type="primary" size="small" onClick={paymentActionHandler}>
            Link Account
          </Button>
        ),
        onClose: closePaymentNotifications,
        duration: 0,
        key: 'SetUpPayment',
      });
    }
  };
  const onSuccess = (value) => {
    const dubWeeklyUserCount = { ...weeklyUserCount };
    dubWeeklyUserCount.actualValues = value.usersCount;
    setWeeklyUserCount(dubWeeklyUserCount);
  };
  const RevenueOnSuccess = (value) => {
    const dubWeeklyUserRev = { ...weeklyUserRevenue };
    dubWeeklyUserRev.actualValues = value.userRevenue;
    setWeeklyUserRevenue(dubWeeklyUserRev);
  };
  const SalesOnSuccess = (value) => {
    const dubWeeklyUserSales = { ...weeklyUserRevenue };
    dubWeeklyUserSales.actualValues = value.userSales;
    setWeeklyUserSales(dubWeeklyUserSales);
  };
  useEffect(() => {
    if (
      firestore.status.requested[shipmentCollection] &&
      !firestore.status.requesting[shipmentCollection]
    ) {
      setShipmentListData([...firestore.ordered.shipments]);
      getCollectionsById('WeeklyUsersCount', selectedClub.id, onSuccess);
      getCollectionsById(
        'weeklyUsersRevenue',
        selectedClub.id,
        RevenueOnSuccess
      );
      getCollectionsById('weeklyUsersSales', selectedClub.id, SalesOnSuccess);
      setLoader(false);
    }
  }, [firestore.status]);
  useEffect(() => {
    if (!selectedClub.checkout.length && !clubPlan) {
      notification.open({
        message: 'Set up plans',
        description:
          'Add at least one plan that customers can choose from in their checkout flow.',
        btn: (
          <Button type="primary" size="small" onClick={actionHandler}>
            Add Plan
          </Button>
        ),
        onClose: closePlanNotifications,
        duration: 0,
        key: 'SetUpPlans',
      });
      return;
    }
    if (clubPlan && !selectedClub.connectedStripeUserId && !stripeConnection) {
      notification.open({
        message: 'Add Your Payment Details',
        description:
          'Link your Clinkee account to stripe to begin automatically charging customers for their membership. ',
        btn: (
          <Button type="primary" size="small" onClick={paymentActionHandler}>
            Link Account
          </Button>
        ),
        onClose: closePaymentNotifications,
        duration: 0,
        key: 'SetUpPayment',
      });
    }
  }, [selectedClub]);

  const viewDetails = (row) => {
    history.push(`/edit-shipment/${row.id}`);
  };

  const deleteInstance = (row) => {
    setLoader(true);
    const payLoad = {
      shipmentId: row.id,
    };
    cloudFunction('removeShipment', payLoad)
      .then(() => {
        setLoader(false);
        message.success('shipment removed successfully');
      })
      .catch((err) => {
        setLoader(false);
        message.error(
          err && err.response && err.response.data && err.response.data.message
        );
      });
  };
  const deleteRow = (row) => {
    confirm({
      title: 'Do you Want to delete this shipment?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        deleteInstance(row);
      },
      onCancel() {},
    });
  };
  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item key="0" onClick={() => viewDetails(row)}>
        <Flex alignItems="center">
          <EyeOutlined />
          <span className="ml-2">View</span>
        </Flex>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => deleteRow(row)}>
        <Flex alignItems="center">
          <DeleteOutlined />
          <span className="ml-2">Delete</span>
        </Flex>
      </Menu.Item>
    </Menu>
  );

  const cardDropdown = () => (
    <Button
      className="gradient-background"
      type="primary"
      size="small"
      onClick={() => {
        setVisible(true);
      }}
    >
      New Shipment
    </Button>
  );
  const tableColumns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => (
        <div className="cursor-pointer">{moment(createdAt).format('L')}</div>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, 'createdAt'),
    },
    {
      title: () => <div className="w-35">Status</div>,
      key: 'status',
      render: (_, elm) => (
        <div className="cursor-pointer">
          <Tooltip
            title={`${elm.usersCount ? elm.usersCount : 0} Total / ${
              elm.paidUserCount ? elm.paidUserCount : 0
            } Paid / ${
              elm.shippedUserCount ? elm.shippedUserCount : 0
            } Shipped`}
          >
            <Progress
              percent={parseInt((elm.paidUserCount / elm.usersCount) * 100, 10)}
              success={{
                percent: parseInt(
                  (elm.shippedUserCount / elm.usersCount) * 100,
                  10
                ),
              }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Plan',
      key: 'plan',
      render: (_, elm) => (
        <div className="cursor-pointer">
          {selectedClub.checkout.find((each) => each.id === elm.planId).title}
        </div>
      ),
    },
    {
      title: 'Sales',
      key: 'sales',
      dataIndex: 'sales',
      render: (sales) => (
        <div className="cursor-pointer">
          <NumberFormat
            displayType="text"
            value={currency(sales)}
            prefix="$"
            thousandSeparator
          />
        </div>
      ),
    },
    {
      title: 'Revenue',
      dataIndex: 'revenue',
      key: 'revenue',
      render: (revenue) => (
        <div className="cursor-pointer">
          <NumberFormat
            displayType="text"
            value={currency(revenue)}
            prefix="$"
            thousandSeparator
          />
        </div>
      ),
    },
    {
      title: 'Users',
      key: 'users',
      render: (record) => (
        <div className="cursor-pointer">{record.usersCount}</div>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, elm) => (
        <div
          className="text-right"
          onClick={() => {
            status = false;
          }}
        >
          <EllipsisDropdown className="transform-90" menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];
  return (
    <div className="shipmentlist">
      {visible && (
        <NewShipmentModel
          visible={visible}
          setVisible={setVisible}
          selectedClub={selectedClub}
          setSelectedPlan={setSelectedPlan}
          selectedPlan={selectedPlan}
          title="Please select the plan you will be shipping "
        />
      )}
      <Row gutter={16}>
        {loader && <Loading className="d-flex justify-content-center" />}
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={16}>
            <Col
              className="shipment-list-cards-main-div"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={8}
            >
              <StatisticWidget
                timePeriod={timePeriod}
                setTimePeriod={setTimePeriod}
                title="Sales"
                weeklyUserCount={weeklyUserCount}
                setWeeklyUserCount={setWeeklyUserCount}
                setWeeklyUserRevenue={setWeeklyUserRevenue}
                weeklyUserRevenue={weeklyUserRevenue}
                weeklyUserSales={weeklyUserSales}
                setWeeklyUserSales={setWeeklyUserSales}
                value={currency(selectedClub.currentSale || 0)}
                status={
                  weeklyUserSales.calculatedValue ||
                  (weeklyUserSales &&
                    weeklyUserSales.actualValues &&
                    weeklyUserSales.actualValues.length > 1 &&
                    calculatePercentage(
                      weeklyUserSales.actualValues[
                        weeklyUserSales.actualValues.length - 1
                      ],
                      weeklyUserSales.actualValues[
                        weeklyUserSales.actualValues.length - 2
                      ]
                    ))
                }
                subtitle={`Total: ${currency(
                  selectedClub.sales || 0
                )} | Potential: ${currency(selectedClub.potentialSale || 0)}`}
              />
            </Col>
            <Col
              className="shipment-list-cards-main-div"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={8}
            >
              <StatisticWidget
                timePeriod={timePeriod}
                title="Revenue"
                value={
                  selectedClub.revenue
                    ? currency(selectedClub.currentRevenue)
                    : currency(0)
                }
                status={
                  weeklyUserRevenue.calculatedValue ||
                  (weeklyUserRevenue &&
                    weeklyUserRevenue.actualValues &&
                    weeklyUserRevenue.actualValues.length > 1 &&
                    calculatePercentage(
                      weeklyUserRevenue.actualValues[
                        weeklyUserRevenue.actualValues.length - 1
                      ],
                      weeklyUserRevenue.actualValues[
                        weeklyUserRevenue.actualValues.length - 2
                      ]
                    ))
                }
                subtitle={
                  selectedClub.revenue
                    ? `Total: ${currency(selectedClub.revenue)}`
                    : `Total: ${currency(0)}`
                }
              />
            </Col>
            <Col
              className="shipment-list-cards-main-div"
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={8}
            >
              <StatisticWidget
                title="Total Users"
                timePeriod={timePeriod}
                value={selectedClub?.userCount || 0}
                status={
                  weeklyUserCount.calculatedValue ||
                  (weeklyUserCount &&
                    weeklyUserCount.actualValues &&
                    weeklyUserCount.actualValues.length > 1 &&
                    weeklyUserCount.actualValues[
                      weeklyUserCount.actualValues.length - 1
                    ] -
                      weeklyUserCount.actualValues[
                        weeklyUserCount.actualValues.length - 2
                      ])
                }
                subtitle={`Total: ${selectedClub.userCount || 0}`}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card title="Shipments" extra={cardDropdown()}>
                <Table
                  className="no-border-last"
                  columns={tableColumns}
                  dataSource={shipmentListData}
                  rowKey="id"
                  pagination={false}
                  onRow={(row) => ({
                    onClick: () => {
                      if (status) {
                        setTimeout(() => {
                          history.push(`/edit-shipment/${row.id}`);
                        }, 200);
                      }
                      status = true;
                    },
                  })}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default ShipmentList;
