import firebase from 'firebase';
import { v1 as uuid } from 'uuid';
import axios from 'axios';
import { API_URI } from '../config/config';

export const getParams = (url) => {
  const params = {};
  if (typeof document !== 'undefined') {
    const parser = document.createElement('a');
    parser.href = url;
    const query = parser.search.substring(1);
    const vars = query.split('&');
    if (vars[0] !== '') {
      for (let i = 0; i < vars.length; i += 1) {
        const pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
  return params;
};

export const getCollectionById = async (collection, id, onSuccess) => {
  const doc = await firebase.firestore().collection(collection).doc(id).get();
  if (!doc.exists) {
    if (onSuccess) onSuccess([]);
  } else if (onSuccess) onSuccess(doc.data());
};

export const getCollectionWithCondition = async (collection, id, onSuccess) => {
  const snapshot = await firebase
    .firestore()
    .collection(collection)
    .where('firest', '==', true)
    .where('second', '==', true)
    .get();
  if (snapshot.empty) {
    if (onSuccess) onSuccess([]);
    return;
  }
  if (onSuccess) {
    const data = [];
    snapshot.forEach((doc) => {
      data.push(doc.data());
    });
    onSuccess(data);
  }
};

export const getCollectionsById = async (collection, id, onSuccess) => {
  const doc = await firebase.firestore().collection(collection).doc(id).get();
  if (!doc.exists) {
    if (onSuccess) onSuccess([]);
  } else if (onSuccess) onSuccess(doc.data());
};

export const addCollections = (collection, newData, onSuccess, onError) => {
  const uid = uuid();
  const data = newData;
  data.id = uid;
  firebase
    .firestore()
    .collection(collection)
    .doc(uid)
    .set(data)
    .then(() => {
      if (onSuccess) onSuccess(data);
    })
    .catch((err) => {
      if (onError) onError(err.message);
    });
};

export const editCollections = (collection, data, onSuccess, onError) => {
  firebase
    .firestore()
    .collection(collection)
    .doc(data.id)
    .set(data)
    .then(() => {
      if (onSuccess) onSuccess(data);
    })
    .catch((err) => {
      if (onError) onError(err.message);
    });
};

export const deleteCollections = (collection, id, onSuccess, onError) => {
  firebase
    .firestore()
    .collection(collection)
    .doc(id)
    .delete()
    .then((docRef) => {
      if (onSuccess) onSuccess(docRef);
    })
    .catch((err) => {
      if (onError) onError(err.message);
    });
};

export const cloudFunction = (name, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_URI + name, {
        data: payload || {},
      })
      .then((result) => {
        // Handle result of your cloud function.
        resolve(result);
      })
      .catch((err) => {
        // Handle error
        reject(err);
      });
  });
};
export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const clearStorage = () => {
  window.location.href = '/';
  return localStorage.clear();
};

export const getItem = (key) => {
  try {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    }
    return null;
  } catch {
    clearStorage();
    return null;
  }
};

export const setItem = (key, data) => {
  try {
    return localStorage.setItem(key, JSON.stringify(data));
  } catch {
    clearStorage();
    return null;
  }
};

export const removeItem = (key) => {
  return localStorage.removeItem(key);
};

export const amt = (numberOfUsers) => {
  const priceTiers = [
    {
      price: 0,
      customers: 50,
    },
    {
      price: 0.99,
      customers: 500,
    },
    {
      price: 0.75,
      customers: 1000,
    },
    {
      price: 0.5,
      customers: 99999999999,
    },
  ];

  let amount = 0;

  priceTiers.some(({ price, customers }) => {
    if (numberOfUsers < customers) {
      amount += price * numberOfUsers;
      return true;
    }

    numberOfUsers -= customers;
    amount += price * customers;
    return false;
  });

  return amount;
};

export const currency = (number) => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
};

export const calculatePercentage = (_current, _last) => {
  const current = _current ? parseFloat(_current) : 0.0;
  const last = _last ? parseFloat(_last) : 0.0;
  return last ? (((current - last) / last) * 100).toFixed(2) : 100;
};
