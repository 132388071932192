import { useState } from 'react';
import { Modal, Form, Input, message } from 'antd';
import { useSelector } from 'react-redux';
import { cloudFunction } from '../../../../../utils/function';
import Loading from '../../../../../sharedComponents/loading';

const AddNewTeamMemeber = (props) => {
  const { showModel, setShowModel } = props;
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const rules = {
    email: [
      {
        required: true,
        message: 'Please enter Email',
      },
      {
        type: 'email',
        message: 'Please enter a valid email',
      },
    ],
  };
  const handleOk = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      cloudFunction('inviteTeamMember', {
        clubName: selectedClub.clubName,
        email: values.email,
        clubId: selectedClub.id,
      })
        .then(() => {
          setLoading(false);
          setShowModel(false);
          message.success(
            'A verification link has been sent to your email account.'
          );
        })
        .catch(() => {
          setLoading(false);
          setShowModel(false);
        });
    });
  };
  const handleCancel = () => {
    setShowModel(false);
  };

  return (
    <>
      <Modal
        title="Invite Member"
        visible={showModel}
        cancelButtonProps={{ style: { display: loading ? 'none' : '' } }}
        okButtonProps={{ style: { display: loading ? 'none' : '' } }}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
        >
          {loading ? (
            <div className=" d-flex justify-content-center">
              <Loading />
            </div>
          ) : (
            <Form.Item
              name="email"
              label="Email"
              rules={rules.email}
              validateTrigger="onBlur"
            >
              <Input placeholder="Email" onPressEnter={() => handleOk()} />
            </Form.Item>
          )}
        </Form>{' '}
      </Modal>
    </>
  );
};

export default AddNewTeamMemeber;
