import { Collapse, InputNumber, Button, Form } from 'antd';
// import { Button as WinebarButton } from '@clinkee/winebar';
import '../style.scss';
import { currency } from '../../../utils/function';

const ManualPayment = (props) => {
  const { header, text, chargeHandler, form, loading } = props;
  const { Panel } = Collapse;
  const rules = {
    amount: [
      {
        required: true,
        message: 'Please enter amount',
      },
    ],
  };
  return (
    <>
      <div className="manual-payment-and-adjustment">
        <Collapse accordion>
          <Panel header={header} className="font-weight-bold font-size-small">
            <div>
              <p className="font-weight-normal">{text}</p>
            </div>
            <div>
              <Form
                layout="vertical"
                form={form}
                onFinish={chargeHandler}
                name="advanced_search"
                className="ant-advanced-search-form"
              >
                <div className="d-flex justify-content-space pt-10 align-items-center">
                  <Form.Item
                    className="w-50 mr-2"
                    name="amount"
                    label="Amount"
                    rules={rules.amount}
                    validateTrigger="onBlur"
                  >
                    <InputNumber
                      min={0}
                      placeholder="amount"
                      onChange={(value) => {
                        form.setFieldsValue({
                          amount: currency(value),
                        });
                      }}
                    />
                  </Form.Item>
                  <Button
                    className="gradient-background w-50"
                    htmlType="submit"
                    loading={loading}
                  >
                    Charge
                  </Button>
                </div>
              </Form>
            </div>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};
export default ManualPayment;
