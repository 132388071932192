import { Collapse, Button } from 'antd';
import { v1 as uuid } from 'uuid';
import {
  DeleteOutlined,
  DownCircleOutlined,
  MinusCircleOutlined,
  UpCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import './style.scss';

const Questions = (props) => {
  const { setError, data, setData } = props;
  const { Panel } = Collapse;

  function callback() {}
  const genExtra = (id) => (
    <>
      <UpCircleOutlined className="px-2" />
      <DownCircleOutlined className="px-1" />
      <DeleteOutlined
        className="mx-2"
        onClick={() => {
          // If you don't want click extra trigger collapse, you can prevent this:
          // event.stopPropagation();
          const dublicateData = { ...data };
          const ques = dublicateData.questions.filter((sin) => sin.id !== id);
          dublicateData.questions = ques;
          setData({ ...dublicateData });
        }}
      />
    </>
  );
  const answerDeleteHandler = (index, ansIndex) => {
    const dubData = { ...data };
    dubData.questions[index].answers = dubData.questions[index].answers.filter(
      (sin, ind) => ind !== ansIndex
    );
    setData({ ...dubData });
  };

  return (
    <div className="Collapse">
      {data &&
        data.questions.length !== 0 &&
        data.questions.map((sin, index) => (
          <Collapse
            // defaultActiveKey={"1"}
            onChange={callback}
            key={sin.id}
          >
            <Panel
              header={
                <input
                  className="w-75 ant-input"
                  defaultValue={sin.question ? sin.question : ''}
                  onChange={(event) => {
                    setError('');
                    const dubData = { ...data };
                    if (!('question' in dubData.questions[index]))
                      dubData.questions[index].question = '';
                    dubData.questions[index].question = event.target.value;
                  }}
                />
              }
              extra={genExtra(sin.id, index)}
            >
              {sin.answers &&
                sin.answers.map((single, ind) => (
                  <div
                    className="d-flex justify-content-center my-2"
                    key={`${single.id}`}
                  >
                    <div className="w-90 d-flex align-items-center">
                      <span
                        className={`font-weight-bold${
                          ind === 0 ? '' : ' text-white'
                        }`}
                      >
                        Answers:
                      </span>
                      <input
                        className="w-73 ant-input mx-3"
                        value={single.answer ? single.answer : ''}
                        onChange={(event) => {
                          setError('');
                          const dublicate = { ...data };
                          dublicate.questions[index].answers[ind].answer =
                            event.target.value;
                          setData({ ...dublicate });
                        }}
                      />
                      <MinusCircleOutlined
                        onClick={() => answerDeleteHandler(index, ind)}
                      />
                    </div>
                  </div>
                ))}
              <div className=" d-flex justify-content-center my-4  ">
                <Button
                  className="w-50"
                  type="dashed"
                  onClick={() => {
                    const dublicateData = { ...data };
                    const answer = {
                      id: uuid(),
                      answer: '',
                    };
                    dublicateData.questions[index].answers.push(answer);
                    setData({ ...dublicateData });
                  }}
                >
                  <PlusOutlined />
                  Add Answer
                </Button>
              </div>
            </Panel>
          </Collapse>
        ))}
    </div>
  );
};
export default Questions;
