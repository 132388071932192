import {
  DashboardOutlined,
  AppstoreOutlined,
  AntDesignOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  FundOutlined,
  // CheckOutlined,
  // BankOutlined,
} from '@ant-design/icons';

const dashBoardNavTree = [
  {
    key: 'dashboards',
    path: '',
    title: 'OPERATIONS',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboards-default',
        path: '/',
        title: 'Shipments',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'users',
        path: '/user',
        title: 'Users',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'products',
        path: '/products',
        title: 'Products',
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const appsNavTree = [
  {
    key: 'apps',
    path: '',
    title: 'CUSTOMER FLOW',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'Questionnaire',
        path: '/questionnaire',
        title: 'Questionnaire',
        icon: QuestionCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const componentsNavTree = [
  {
    key: 'components',
    path: '',
    title: 'Account',
    icon: AntDesignOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'setting',
        path: '/settings/club-info',
        title: 'Settings',
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [
          // {
          //   key: 'General',
          //   path: '',
          //   title: 'General',
          //   icon: UserOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: 'Check Out',
          //   path: '',
          //   title: 'Check Out',
          //   icon: CheckOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: 'Accounts and Billing',
          //   path: '',
          //   title: 'Accounts and Billing',
          //   icon: BankOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },
        ],
      },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...appsNavTree,
  ...componentsNavTree,
];

export default navigationConfig;
