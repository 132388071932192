import { InputNumber, Input, Row, Col, Card, Form } from 'antd';
import { withRouter } from 'react-router-dom';
import PlanCard from './planCard';
import PaymentMgt from './paymentManagement';
import ShipmentDetails from './shipmentDetails';
import '../style.scss';

const rules = {
  name: [
    {
      required: true,
      message: 'Please enter First Name',
    },
  ],
  lastName: [
    {
      required: true,
      message: 'Please enter Last Name',
    },
  ],
  phoneNumber: [
    {
      required: true,
      message: 'Please input Phone Number',
    },
    {
      type: 'number',
      message: 'Please enter a valid Phone Number',
    },
  ],
  email: [
    {
      required: true,
      message: 'Please input email',
    },
    {
      type: 'email',
      message: 'Please enter a valid email',
    },
  ],
  state: [
    {
      required: true,
      message: 'Please enter State',
    },
  ],
  city: [
    {
      required: true,
      message: 'Please enter City',
    },
  ],
  address1: [
    {
      required: true,
      message: 'Please enter Address 1',
    },
  ],
  code: [
    {
      required: true,
      message: 'Please enter Zip Code',
    },
    {
      type: 'number',
      message: 'Please enter a valid Zip Code',
    },
  ],
};
const GeneralField = (props) => {
  const {
    mode,
    selectedClub,
    id,
    user,
    setSelectedPlan,
    selectedPlan,
    setLastChargeDate,
    setChargeDate,
    form,
    shipment,
    setShipment,
  } = props;
  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={13}>
        <Card className="user-detail" title="User Details">
          <Form
            layout="vertical"
            form={form}
            name="user_form"
            className="ant-advanced-search-form"
          >
            <Row>
              <Col className="mx-2" xs={24} sm={24} md={12} lg={11} xlg={11}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  validateTrigger="onBlur"
                  rules={rules.name}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={17} lg={11} xlg={11}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={rules.lastName}
                  validateTrigger="onBlur"
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mx-2" xs={24} sm={24} md={12} lg={11} xlg={11}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={rules.phoneNumber}
                  validateTrigger="onBlur"
                >
                  <InputNumber placeholder="Phone Number" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={17} lg={11} xlg={11}>
                <Form.Item
                  name="userEmail"
                  label="Email"
                  rules={rules.email}
                  validateTrigger="onBlur"
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col className="mx-2" xs={24} sm={24} md={12} lg={11} xlg={11}>
                <Form.Item
                  name="address1"
                  label="Address 1"
                  rules={rules.address1}
                  validateTrigger="onBlur"
                >
                  <Input placeholder="Address 1" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={17} lg={11} xlg={11}>
                <Form.Item
                  name="address2"
                  rules={null}
                  label="Address 2"
                  validateTrigger="onBlur"
                >
                  <Input placeholder="Address 2" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mx-2" xs={24} sm={24} md={12} lg={11} xlg={11}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={rules.city}
                  validateTrigger="onBlur"
                >
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={17} lg={11} xlg={11}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={rules.state}
                  validateTrigger="onBlur"
                >
                  <Input placeholder="State" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mx-2" xs={24} sm={24} md={12} lg={11} xlg={11}>
                <Form.Item
                  name="zipCode"
                  label="Zip Code"
                  rules={rules.code}
                  validateTrigger="onBlur"
                >
                  <InputNumber placeholder="Zip Code" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>{' '}
        {mode === 'EDIT' && (
          <ShipmentDetails shipment={shipment} setShipment={setShipment} />
        )}
      </Col>{' '}
      <Col xs={24} sm={24} md={11}>
        <Card title="Plan">
          <PlanCard
            selectedClub={selectedClub}
            setSelectedPlan={setSelectedPlan}
            selectedPlan={selectedPlan}
            setLastChargeDate={setLastChargeDate}
            setChargeDate={setChargeDate}
            userId={id}
          />
        </Card>
        {mode === 'EDIT' && (
          <Card className="payment-card" title="Payment Management">
            <PaymentMgt userId={id} user={user} />
          </Card>
        )}
      </Col>
    </Row>
  );
};
export default withRouter(GeneralField);
