import { ChromePicker } from 'react-color';

const ColorPicker = (props) => {
  const { selectedColor, setSelectedColor } = props;

  // const hexToRGBARestructure = (hex, a) => {
  //   // let result = hexToRGBA(hex);
  //   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  //   return result
  //     ? {
  //         r: parseInt(result[1], 16),
  //         g: parseInt(result[2], 16),
  //         b: parseInt(result[3], 16),
  //         a: 1,
  //       }
  //     : null;
  // };

  // set _color
  const hexify = (color) => {
    const values = color
      .replace(/rgba?\(/, '')
      .replace(/\)/, '')
      .replace(/[\s+]/g, '')
      .split(',');
    const a = parseFloat(values[3] || 1);
    const r = Math.floor(a * parseInt(values[0], 10) + (1 - a) * 255);
    const g = Math.floor(a * parseInt(values[1], 10) + (1 - a) * 255);
    const b = Math.floor(a * parseInt(values[2], 10) + (1 - a) * 255);
    return `#${`0${r.toString(16)}`.slice(-2)}${`0${g.toString(16)}`.slice(
      -2
    )}${`0${b.toString(16)}`.slice(-2)}`;
  };

  const handleTheme = (color) => {
    if (color.rgb.a === 1) {
      const myHex = hexify(
        `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
      );
      const dub = { ...selectedColor };
      dub.clubColor = myHex;
      if (setSelectedColor) {
        setSelectedColor({ ...dub });
      }
    }
  };
  return (
    <div>
      <ChromePicker color={selectedColor.clubColor} onChange={handleTheme} />
    </div>
  );
};
export default ColorPicker;
