import { useEffect, useState } from 'react';
import { Card, Table, Input, Button, Menu, Modal } from 'antd';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { deleteCollections, currency } from '../../../utils/function';
import AvatarStatus from '../../../sharedComponents/avatarStatus';
import EllipsisDropdown from '../../../sharedComponents/ellipsisDropdown';
import Flex from '../../../sharedComponents/flex';
import Loading from '../../loader';
import utils from '../../utils';

const { confirm } = Modal;
// const { Option } = Select.Option;

// const getStockStatus = (stockCount) => {
//   if (stockCount >= 10) {
//     return (
//       <>
//         <Badge status="success" />
//         <span>In Stock</span>
//       </>
//     );
//   }
//   if (stockCount < 10 && stockCount > 0) {
//     return (
//       <>
//         <Badge status="warning" />
//         <span>Limited Stock</span>
//       </>
//     );
//   }
//   if (stockCount === 0) {
//     return (
//       <>
//         <Badge status="error" />
//         <span>Out of Stock</span>
//       </>
//     );
//   }
//   return null;
// };

// const categories = ['Cloths', 'Bags', 'Shoes', 'Watches', 'Devices'];

const ProductList = () => {
  const history = useHistory();
  const firestore = useSelector((state) => state.firestore);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loader, setLoader] = useState(true);
  const [ProductListData, setProductListData] = useState([]);
  const [list, setList] = useState([]);
  let status = true;
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const productsCollection = `products?where=clubId,==,${selectedClub.id}`;
  useFirestoreConnect([
    {
      collection: 'products',
      where: [['clubId', '==', selectedClub.id]],
    },
  ]);
  useEffect(() => {
    if (
      firestore.status.requested[productsCollection] &&
      !firestore.status.requesting[productsCollection]
    ) {
      setProductListData([...firestore.ordered.products]);
      setList([...firestore.ordered.products]);
      setLoader(false);
    }
  }, [firestore.status]);

  const addProduct = () => {
    history.push(`/add-product`);
  };
  const RowClick = (rowId, path) => {
    if (status) {
      setTimeout(() => {
        history.push(`${path}${rowId}`);
      }, 200);
    }
    status = true;
  };

  const viewDetails = (row) => {
    history.push(`/edit-product/${row.id}`);
  };

  const deleteInstance = (row) => {
    const objKey = 'id';
    let data = list;
    if (selectedRows.length > 1) {
      selectedRows.forEach((elm) => {
        data = utils.deleteArrayRow(data, objKey, elm.id);
        deleteCollections('products', elm.id);
        setList(data);
        setSelectedRows([]);
      });
    } else {
      data = utils.deleteArrayRow(data, objKey, row.id);
      deleteCollections('products', row.id);
      setList(data);
    }
  };
  const deleteRow = (row) => {
    confirm({
      title: 'Do you Want to delete these items?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        deleteInstance(row);
      },
      onCancel() {},
    });
  };

  const dropdownMenu = (row) => {
    return (
      <Menu>
        <Menu.Item key="0" onClick={() => viewDetails(row)}>
          <Flex alignItems="center">
            <EyeOutlined />
            <span className="ml-2">View Details</span>
          </Flex>
        </Menu.Item>
        <Menu.Item key="1" onClick={() => deleteRow(row)}>
          <Flex alignItems="center">
            <DeleteOutlined />
            <span className="ml-2">
              {selectedRows.length > 0
                ? `Delete (${selectedRows.length})`
                : 'Delete'}
            </span>
          </Flex>
        </Menu.Item>
      </Menu>
    );
  };

  const tableColumns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      render: (_, record) => (
        <div className="d-flex cursor-pointer">
          <AvatarStatus
            size={60}
            type="square"
            src={record.productImage || ''}
            name={record.productName}
          />
        </div>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, 'productName'),
    },
    {
      title: 'Product Price',
      dataIndex: 'productPrice',
      key: 'productPrice',
      render: (productPrice) => (
        <div className="cursor-pointer">
          <NumberFormat
            displayType="text"
            value={currency(productPrice)}
            prefix="$"
            thousandSeparator
          />
        </div>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, 'productPrice'),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, elm) => (
        <div
          className="text-right"
          onClick={() => {
            status = false;
          }}
        >
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  // const rowSelection = {
  //   onChange: (key, rows) => {
  //     setSelectedRows(rows);
  //     setSelectedRowKeys(key);
  //   },
  // };

  const onSearch = (e) => {
    const { value } = e.currentTarget;
    const searchArray = [...ProductListData];
    const data = utils.wildCardSearch(searchArray, value);
    setList(data);
    // setSelectedRowKeys([]);
  };

  // const handleShowCategory = (value) => {
  //   if (value !== 'All') {
  //     const key = 'category';
  //     const data = utils.filterArray(ProductListData, key, value);
  //     setList(data);
  //   } else {
  //     setList(ProductListData);
  //   }
  // };

  return (
    <>
      <Card>
        <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
          <Flex className="mb-1" mobileFlex={false}>
            <div className="mr-md-3 mb-3">
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                onChange={(e) => onSearch(e)}
              />
            </div>
            {/* <div className="mb-3">
            <Select
              defaultValue="All"
              className="w-100"
              style={{ minWidth: 180 }}
              onChange={handleShowCategory}
              placeholder="Category"
            >
              {categories.map((elm) => (
                <Option key={elm} value={elm}>
                  {elm}
                </Option>
              ))}
            </Select>
          </div> */}
          </Flex>
          <div>
            <Button
              onClick={addProduct}
              type="primary"
              icon={<PlusCircleOutlined />}
              block
            >
              Add product
            </Button>
          </div>
        </Flex>
        <div className="table-responsive">
          <Table
            columns={tableColumns}
            dataSource={list}
            rowKey="id"
            onRow={(row) => ({
              onClick: () => {
                RowClick(row.id, '/edit-product/');
              },
            })}
          />
        </div>
      </Card>
      {loader && <Loading />}
    </>
  );
};

export default ProductList;
