import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button, Col, Row, Card, Input, message } from 'antd';
import { useSelector } from 'react-redux';
import Flex from '../../../sharedComponents/flex';
import InputViewForm from '../../../sharedComponents/viewFormat';
import { editCollections } from '../../../utils/function';
import Content from './content';
import './style.scss';

const CustomDomain = (props) => {
  const { mode, param } = props;
  const [formMode, setFormMode] = useState('VIEW');
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  const [data, setData] = useState({
    clubLogo: '',
    clubColor: '',
  });

  useEffect(() => {
    if (mode === 'EDIT') {
      if (selectedClub) {
        form.setFieldsValue({
          id: selectedClub.id,
          customDomain: selectedClub.customDomain
            ? selectedClub.customDomain
            : '',
        });
      }
      const dublicateData = { ...data };
      dublicateData.clubColor = selectedClub.clubColor;
      dublicateData.clubLogo = selectedClub.clubLogo;
      setData({ ...dublicateData });
    }
  }, [form, mode, param, props, formMode, selectedClub]);

  const onSuccess = () => {
    setSubmitLoading(false);
    setFormMode('VIEW');
    message.success(`Custom domain saved`);
  };

  const onError = () => {
    setSubmitLoading(false);
    message.error('Something went wrong please try again');
  };

  const onFinish = () => {
    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        const dublicateClub = { ...selectedClub };
        dublicateClub.customDomain = values.customDomain
          ? values.customDomain
          : '';
        if (mode === 'ADD') {
          // addCollections('users', values, onSuccess, onError);
        } else if (mode === 'EDIT') {
          editCollections('clubs', dublicateClub, onSuccess, onError);
        }
      })
      .catch(() => {
        setSubmitLoading(false);
        message.error('Please enter all required field ');
      });
  };

  return (
    <Row gutter={16} className="club-info-container">
      <Col xs={24} sm={24} md={8} lg={8} className="px-4">
        <Content
          heading="Custom Domain"
          content="Use a custom domain for your Clinkee checkout to offer customers a seamless experience.
Custom domains come with an SSL certificate."
        />
      </Col>
      <Col xs={24} sm={24} md={16} lg={16} className="settings-club-info">
        <Form
          layout="vertical"
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
        >
          <Col xs={24} sm={24} md={13} lg={24} xlg={24}>
            <Card>
              <Flex
                className="py-2 font-weight-bold"
                mobileFlex={false}
                justifyContent="between"
                alignItems="center"
              >
                <h2 className="mb-3">
                  {formMode === 'ADD' ? 'Add Club Info' : `Edit Custom Domain`}{' '}
                </h2>
                <div className="mb-3">
                  {formMode === 'EDIT' && (
                    <Button
                      className="mr-2"
                      onClick={() => {
                        setFormMode('VIEW');
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    type="primary"
                    loading={submitLoading}
                    onClick={() => {
                      if (formMode === 'EDIT') {
                        onFinish();
                      } else {
                        setFormMode('EDIT');
                      }
                    }}
                  >
                    {formMode === 'EDIT' ? 'Save' : `Edit`}
                  </Button>
                </div>
              </Flex>
              <Form.Item
                name="customDomain"
                label="Custom Domain"
                validateTrigger="onBlur"
              >
                {formMode === 'EDIT' ? (
                  <Input placeholder="Custom Domain" />
                ) : (
                  <InputViewForm
                    value={selectedClub && selectedClub.customDomain}
                  />
                )}
              </Form.Item>{' '}
            </Card>{' '}
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default withRouter(CustomDomain);
