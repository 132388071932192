import { useState } from 'react';
import { Input, Alert, Form } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { MotionDiv, Button } from '@clinkee/winebar';
import firebase from 'firebase';
import {
  getCollectionById,
  getParams,
  editCollections,
  validateEmail,
} from '../utils/function';

const FormItem = Form.Item;
let userId = '';
const RegisterForm = (props) => {
  const { history, location } = props;
  const queryString =
    location && location.search && location.search
      ? getParams(location.search)
      : '';
  const email = queryString && queryString.email ? queryString.email : '';
  const clubId = queryString && queryString.cId ? queryString.cId : '';
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const initialCredential = {
    password: '',
    confirmPassword: '',
  };

  const onEditClubSuccess = () => {
    history.push('/');
  };
  const onSuccessClub = (data) => {
    data.adminId.push(userId);
    editCollections('clubs', data, onEditClubSuccess);
  };

  const onSuccessAdmin = (data) => {
    data.club.push(clubId);
    editCollections('clubAdmin', data);
  };

  const onLogin = (values) => {
    const { password, confirmPassword } = values;
    setError('');
    if (password !== confirmPassword) {
      setError('Passwords are not matching.');
    } else if (!email) {
      setError('Email is Required');
    } else if (email && !validateEmail(email)) {
      setError('Email is invalid');
    } else if (!clubId) {
      setError('Club not Found');
    } else {
      setLoading(true);
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((data) => {
          const { uid } = data.user;
          userId = uid;
          const userObj = {
            email,
            uid,
            clubs: [clubId],
          };
          firebase
            .firestore()
            .collection('clubAdmin')
            .doc(uid)
            .set(userObj)
            .then(() => {
              getCollectionById('clubs', clubId, onSuccessClub);
            })
            .catch(() => {
              setLoading(false);
            });
        })
        .catch((err) => {
          if (err.code === 'auth/email-already-in-use') {
            firebase
              .auth()
              .signInWithEmailAndPassword(email, password)
              .then((user) => {
                userId = user.user.uid;
                getCollectionById('clubAdmin', userId, onSuccessAdmin);
                getCollectionById('clubs', clubId, onSuccessClub);
              })
              .catch(() => {
                setLoading(false);
                if (err.code === 'auth/wrong-password') {
                  setError(
                    'This user already exist with different password please enter the correct password'
                  );
                } else {
                  setError(err.message);
                }
              });
          } else {
            setError(err.message);
          }
        });
    }
  };

  return (
    <>
      <MotionDiv showMessage={!!error}>
        <Alert type="error" showIcon message={error} />
      </MotionDiv>
      <Form
        layout="vertical"
        name="login-form"
        initialValues={initialCredential}
        onFinish={onLogin}
      >
        <FormItem
          name="password"
          label="Password"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
            {
              min: 8,
              message: 'Password length should be 8 characters',
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined className="text-primary" />} />
        </FormItem>

        <FormItem
          name="confirmPassword"
          label="Confirm Password"
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
            {
              min: 8,
              message: 'Password length should be 8 characters',
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input.Password prefix={<LockOutlined className="text-primary" />} />
        </FormItem>

        <FormItem>
          <Button
            className="gradient-background"
            htmlType="submit"
            loading={loading}
            buttonText="Sign Up"
          />
        </FormItem>
      </Form>
    </>
  );
};
export default withRouter(RegisterForm);
