import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase'; // <- needed if using firebase
import { firestoreReducer } from 'redux-firestore'; // <- needed if using firestore
import clubReducer from './clubReducer';
import clubPlanReducer from './planReducer';
import commonReducer from './commonReducer';
import stripeConnection from './stripeAccountConnection';
import Theme from './Theme';

export const Reducers = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  club: clubReducer,
  plan: clubPlanReducer,
  stripeConnection,
  common: commonReducer,
  theme: Theme,
});
