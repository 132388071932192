const Content = (props) => {
  const { heading, content } = props;
  return (
    <>
      <div className="settings-inner-content">
        <div className="content-heading">
          <h3>{heading || ''}</h3>
        </div>
        <div className="inner-content">
          <p>{content || ''}</p>
        </div>
      </div>
    </>
  );
};

export default Content;
