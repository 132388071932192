import { SET_LOADER } from './ActionTypes';

const INITIAL_STATE = {
  loader: false,
};
const clubReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    default:
      return state;
  }
};

export default clubReducer;
