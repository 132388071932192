import { Col } from 'antd';
import BillingInfoForm from './billingInfoForm';
import SelectCreditCard from './selectCreditCard';

const BillingInfo = () => {
  return (
    <Col xs={24} sm={24} md={18} lg={18} className="Billing-Info">
      <SelectCreditCard />
      <BillingInfoForm mode="EDIT" />
    </Col>
  );
};

export default BillingInfo;
