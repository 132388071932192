import { Card, Row, Col } from 'antd';
import Billing from './billing';
import './style.scss';
import Content from '../../../settingsInnerContent/content';

const PaymentManagement = () => {
  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={7} lg={7} className="px-4">
        <Content
          heading="Credit Card Info"
          content="Manage your billing credit card. "
        />
      </Col>
      <Col xs={24} sm={24} md={17} lg={17}>
        <div className="payment-management">
          <Card title="">
            <h2>Select Card</h2>
            <h2>{/* $165.23 <span>balance</span> */}</h2>
            <Billing />
          </Card>
        </div>
      </Col>
    </Row>
  );
};
export default PaymentManagement;
