import { Table } from 'antd';
import { withRouter } from 'react-router-dom';

const UserTable = (props) => {
  const { tableColumns, usersData, rowClickHandler } = props;
  return (
    <>
      <Table
        rowKey="id"
        columns={tableColumns}
        dataSource={usersData || []}
        onRow={(row) => ({
          onClick: () => {
            rowClickHandler(row);
          },
        })}
      />
    </>
  );
};
export default withRouter(UserTable);
