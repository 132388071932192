/* eslint-disable no-sequences */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';
import Invoices from './invoices';
// import CurrentBilling from './currentBilling';
import NextBilling from './nextBilling';
import { amt } from '../../../../utils/function';

const CurrentPlan = () => {
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState();
  useEffect(() => {
    setList(amt(selectedClub.userCount));
    setLoading(false);
  });
  return (
    <Col xs={24} sm={24} md={18} lg={18}>
      <Invoices />
      {/* <CurrentBilling /> */}
      <NextBilling loading={loading} list={list} />
    </Col>
  );
};
export default CurrentPlan;
