import { useState, useEffect } from 'react';
import { Table, Button, Tooltip, Modal, message } from 'antd';
import {
  DeleteOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { cloudFunction, editCollections } from '../../../utils/function';
import AddNewCardForm from './addNewCard';
import VisaImage from '../../../assets/images/visa.png';
import MasterImage from '../../../assets/images/master.png';

const { Column } = Table;
const { confirm } = Modal;
const Billing = (props) => {
  const { userData } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
  const [creditCards, setCreditCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();

  useEffect(() => {
    if (userData) {
      setCreditCards([...userData.cards]);
      let selectCard =
        userData.cards && userData.cards.length > 0 ? userData.cards : '';
      selectCard = selectCard
        ? selectCard.find((sin) => sin.active === true)
        : '';
      selectCard = selectCard ? selectCard.key : '';
      setSelectedRowKeys([selectCard || '']);
    }
  }, [userData, setSelectedRowKeys]);
  const [modalVisible, setModalVisible] = useState(false);

  const onSuccessActive = () => {
    message.success('Selected payment method updated Successfully.');
  };

  const onErrorActive = () => {
    message.error('Something went wrong, Please try again.');
  };

  const onSelectChange = (rowKeys) => {
    const dublicateUser = { ...userData };
    dublicateUser.cards = dublicateUser.cards.map((each) => {
      const card = { ...each };
      if (card.key === rowKeys[0]) {
        setSelectedRowKeys(rowKeys);
        card.active = true;
        return card;
      }
      card.active = false;
      return card;
    });
    editCollections(
      'users',
      { ...dublicateUser },
      onSuccessActive,
      onErrorActive
    );
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const switchBrand = (brand) => {
    switch (brand) {
      case 'MasterCard':
      case 'Master':
        return <img src={MasterImage} alt={brand} />;
      case 'Visa':
        return <img src={VisaImage} alt={brand} />;
      default:
        return '';
    }
  };
  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
    onChange: onSelectChange,
  };
  const locale = {
    emptyText: (
      <div className="text-center my-4">
        <img
          src="/img/others/img-7.png"
          alt="Add credit card"
          style={{ maxWidth: '90px' }}
        />
        <h3 className="mt-3 font-weight-light">Please add a credit card!</h3>
      </div>
    ),
  };
  return (
    <>
      {/* <h2 className="mb-4">Billing</h2> */}
      <Table
        locale={locale}
        dataSource={creditCards}
        rowSelection={rowSelection}
        pagination={false}
      >
        <Column
          title="Card type"
          key="cardType"
          render={(text, record) => (
            <>
              {switchBrand(record.cardType)}
              <span className="ml-2">{record.cardType}</span>
            </>
          )}
        />
        <Column title="Card Number" dataIndex="cardNumber" key="cardNumber" />
        <Column title="Expires on" dataIndex="exp" key="exp" />
        <Column
          title=""
          key="actions"
          className="text-right"
          render={(text, record) => (
            <Tooltip title="Remove card">
              <Button
                type="link"
                shape="circle"
                icon={
                  loading && id === record.key ? (
                    <LoadingOutlined />
                  ) : (
                    <DeleteOutlined />
                  )
                }
                onClick={() => {
                  setId(record.key);
                  confirm({
                    title: 'Do you Want to delete this items?',
                    icon: <ExclamationCircleOutlined />,
                    content: '',
                    onOk() {
                      const payLoad = {
                        userId: userData.id,
                        key: record.key,
                      };
                      setLoading(true);
                      cloudFunction('removeCardOfUser', payLoad)
                        .then(() => {
                          message.success('credit card removed successfully');
                          setLoading(false);
                        })
                        .catch((err) => {
                          message.error(
                            err &&
                              err.response &&
                              err.response.data &&
                              err.response.data.message
                          );
                          setLoading(false);
                        });
                    },
                    onCancel() {},
                  });
                }}
              />
            </Tooltip>
          )}
        />
      </Table>
      <div className="mt-3 text-right">
        <Button type="primary" onClick={showModal}>
          Add new card
        </Button>
      </div>
      <AddNewCardForm
        visible={modalVisible}
        setVisible={setModalVisible}
        onCancel={closeModal}
        userId={userData.id}
      />
    </>
  );
};

export default Billing;
