import NewClubUploadLogo from '../NewClubUploadLogo';
import NewClubColorPicker from '../NewClubColorPicker';
import NewClubQuestions from '../NewClubQuestions';
import { getParams } from '../../utils/function';

const ClubReturn = (props) => {
  const { location, history, data, setData } = props;
  const step = location && location.search ? getParams(location.search) : '';
  const goToClub = () => {
    history.push('/create-new-club');
  };
  const ClubReturnHandler = (value) => {
    switch (value.step) {
      case '2':
        return data && data.clubName ? (
          <NewClubUploadLogo history={history} data={data} setData={setData} />
        ) : (
          goToClub()
        );
      case '3':
        return data && data.clubName ? (
          <NewClubColorPicker
            history={props.history}
            setData={setData}
            data={data}
          />
        ) : (
          goToClub()
        );
      case '4':
        return data && data.clubName && data.clubColor ? (
          <NewClubQuestions history={history} data={data} setData={setData} />
        ) : (
          goToClub()
        );

      default:
        return goToClub();
    }
  };
  return <>{ClubReturnHandler(step)}</>;
};
export default ClubReturn;
