import {
  AntDesignOutlined,
  UserOutlined,
  BankOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';

const navigationOneNavTree = [
  {
    key: 'navigation1',
    submenu: [
      {
        key: 'navigationOne',
        path: '',
        title: 'General',
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'clubInfo',
            path: '/settings/club-info',
            title: 'Club Info',
            icon: '',
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'Team',
            path: '/settings/current-team-memebers',
            title: 'Team',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];
const navigationTwoNavTree = [
  {
    key: 'navigation2',
    // path: '',
    // title: 'Account',
    icon: AntDesignOutlined,
    // breadcrumb: true,
    submenu: [
      {
        key: 'A&B',
        path: '',
        title: 'Accounting and Billing',
        icon: BankOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'billingInformation',
            path: '/settings/billing-info',
            title: 'Billing Information',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'accountStatus',
            path: '/settings/account-status',
            title: 'Account Status',
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'currentPlan',
            path: '/settings/current-plan',
            title: 'Current Plan',
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];
const navigationThreeNavTree = [
  {
    key: 'navigation7',
    // path: '',
    // title: 'Account',
    // icon: DollarOutlined,
    // breadcrumb: true,
    submenu: [
      {
        key: 'checkout',
        path: '',
        title: 'Checkout',
        icon: ShoppingCartOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'checkoutInfo',
            path: '/settings/checkout',
            title: 'Club Plans',
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'paymentProvider',
            path: '/settings/payment-provider',
            title: 'Payment Provider',
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'customDomain',
            path: '/settings/custom-Domain',
            title: 'Custom Domain',
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];
const navigationConfig = [
  ...navigationOneNavTree,
  ...navigationTwoNavTree,
  ...navigationThreeNavTree,
];
export default navigationConfig;
