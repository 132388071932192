/* eslint-disable no-sequences */
import { useState } from 'react';
import moment from 'moment';
import { Card, Table, Input, Menu, Tag } from 'antd';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import EllipsisDropdown from '../../../sharedComponents/ellipsisDropdown';
import Flex from '../../../sharedComponents/flex';
import utils from '../../utils';

const ShipmentDetails = (props) => {
  const { shipment, setShipment, history } = props;
  const [shipmentBackup] = useState(shipment);
  const viewDetails = (row) => {
    history.push(`/edit-shipment/${row.shipmentId}`);
  };

  // const deleteInstance = (row) => {
  //   const objKey = 'id';
  //   let data = shipment;
  //   if (selectedRows.length > 1) {
  //     selectedRows.forEach((elm) => {
  //       data = utils.deleteArrayRow(data, objKey, elm.id);
  //       deleteCollections('products', elm.id);
  //       setList(data);
  //       setSelectedRows([]);
  //     });
  //   } else {
  //     data = utils.deleteArrayRow(data, objKey, row.id);
  //     deleteCollections('products', row.id);
  //     setList(data);
  //   }
  // };
  // const deleteRow = (row) => {
  //   confirm({
  //     title: 'Do you Want to delete these items?',
  //     icon: <ExclamationCircleOutlined />,
  //     content: '',
  //     onOk() {
  //       deleteInstance(row);
  //     },
  //     onCancel() {},
  //   });
  // };

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item onClick={() => viewDetails(row)}>
        <Flex alignItems="center">
          <EyeOutlined />
          <span className="ml-2">View Details</span>
        </Flex>
      </Menu.Item>
      {/* <Menu.Item onClick={() => deleteRow(row)}>
        <Flex alignItems="center">
          <DeleteOutlined />
          <span className="ml-2">
            {selectedRows.length > 0
              ? `Delete (${selectedRows.length})`
              : 'Delete'}
          </span>
        </Flex>
      </Menu.Item> */}
    </Menu>
  );

  const tableColumns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => (
        <div>{createdAt ? moment(createdAt).format('L') : ''}</div>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, 'createdAt'),
    },
    {
      title: 'Shipping',
      dataIndex: 'shipmentStatus',
      sorter: (a, b) => (a, b, 'productName'),
    },
    // {
    //   title: 'Category',
    //   dataIndex: 'category',
    //   sorter: (a, b) => utils.antdTableSorter(a, b, 'category'),
    // },
    {
      title: 'Payment',
      dataIndex: 'paymentStatus',
      render: (paymentStatus) => (
        <Tag
          className="text-capitalize pt-0"
          color={paymentStatus ? 'cyan' : 'red'}
        >
          {paymentStatus ? 'Paid' : 'Unpaid'}
        </Tag>
      ),
      sorter: (a, b) => (a, b, 'paymentStatus'),
    },
    {
      title: 'Items',
      key: 'item',
      render: (_, elm) => (
        <div>
          <span>{`${elm.totalShippedItem}/${elm.totalItemInPlan}`}</span>
        </div>
      ),
    },
    // {
    //   title: 'Stock',
    //   dataIndex: 'stock',
    //   sorter: (a, b) => utils.antdTableSorter(a, b, 'stock'),
    // },
    // {
    //   title: 'Status',
    //   dataIndex: 'stock',
    //   render: (stock) => (
    //     <Flex alignItems="center">{getStockStatus(stock)}</Flex>
    //   ),
    //   sorter: (a, b) => utils.antdTableSorter(a, b, 'stock'),
    // },
    {
      title: '',
      dataIndex: 'actions',
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];
  //   const rowSelection = {
  //     onChange: (key, rows) => {
  //       setSelectedRows(rows);
  //       setSelectedRowKeys(key);
  //     },
  //   };
  const onSearch = (e) => {
    const { value } = e.currentTarget;
    const data = utils.wildCardSearch(
      shipmentBackup ? [...shipmentBackup] : [],
      value
    );
    setShipment(data);
  };
  return (
    <Card title="Shipments">
      <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
        <Flex className="mb-1" mobileFlex={false}>
          <div className="mr-md-3 mb-3">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              onChange={(e) => onSearch(e)}
            />
          </div>
        </Flex>
      </Flex>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={shipment} // data is in list state
          rowKey="id"
        />
      </div>
    </Card>
  );
};
export default withRouter(ShipmentDetails);
