import { useState, useEffect } from 'react';
import { v1 as uuid } from 'uuid';
import { withRouter } from 'react-router-dom';
import { Form, Button, Col, Row, Card, Input, message, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase';
import Flex from '../../../sharedComponents/flex';
import InputViewForm from '../../../sharedComponents/viewFormat';
import { setSelectedClub } from '../../../store/actions/clubActions';
import { editCollections } from '../../../utils/function';
import Uploadavatar from '../../UploadLogo';
import ColorPicker from '../../colorPicker';
import Content from './content';
import Loading from '../../loader';
import './style.scss';
import { setClubPlan } from '../../../store/actions/culbPlanAction';
import { setStripeAccountConnection } from '../../../store/actions/stripeAccountConnection';

const ClubInfo = (props) => {
  const { mode, param, history } = props;
  const dispatch = useDispatch();
  const { Column } = Table;
  const [formMode, setFormMode] = useState('VIEW');
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const firestore = useSelector((state) => state.firestore);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

  const [club, setClub] = useState([]);
  const [data, setData] = useState({
    clubLogo: '',
    clubColor: '',
  });

  useEffect(() => {
    if (mode === 'EDIT') {
      if (selectedClub) {
        setClub(
          firestore.ordered &&
            firestore.ordered.clubs &&
            firestore.ordered.clubs.filter((sin) => sin.status !== 'cancel')
        );
        setSelectedRowKeys([selectedClub.id]);
        setLoader(false);
        form.setFieldsValue({
          id: selectedClub.id,
          selectedClubId: selectedClub.id,
          clubName: selectedClub.clubName,
        });
      }
      const dublicateData = { ...data };
      dublicateData.clubColor = selectedClub.clubColor;
      dublicateData.clubLogo = selectedClub.clubLogo;
      setData({ ...dublicateData });
    }
  }, [form, mode, param, props, formMode, setClub, selectedClub]);
  // todo
  const rules = {
    name: [
      {
        required: true,
        message: 'Please enter First Name',
      },
    ],
    website: [
      {
        required: false,
        message: 'Please enter website',
      },
    ],
  };

  const onSuccess = () => {
    setSubmitLoading(false);
    setFormMode('VIEW');
    message.success(`Club Info saved`);
  };

  const onError = () => {
    setSubmitLoading(false);
    message.error('Something went wrong please try again');
  };

  const onFinish = () => {
    setSubmitLoading(true);
    form
      .validateFields()
      .then((values) => {
        const dublicateClub = { ...selectedClub };
        dublicateClub.clubName = values.clubName ? values.clubName : '';
        dublicateClub.clubLogo = data.clubLogo ? data.clubLogo : '';
        dublicateClub.clubColor = data.clubColor ? data.clubColor : '';
        if (mode === 'ADD') {
          // addCollections('users', values, onSuccess, onError);
        } else if (mode === 'EDIT') {
          editCollections('clubs', dublicateClub, onSuccess, onError);
        }
      })
      .catch(() => {
        setSubmitLoading(false);
        message.error('Please enter all required field ');
      });
  };

  const addPhoto = (files) => {
    const { file } = files;
    const uid = uuid();
    const storage = firebase.storage();
    const imageRef = storage.ref('clubLogos').child(uid);
    const imgData = imageRef.put(file);
    imgData.on(
      'state_changed',
      () => {
        setLoading(true);
      },
      () => {
        setLoading(false);
      },
      () => {
        imageRef.getDownloadURL().then((downloadURL) => {
          const dubData = { ...data };
          dubData.clubLogo = downloadURL;
          setData(dubData);
          setLoading(false);
        });
      }
    );
  };
  const onSelectChange = (rowKeys) => {
    const selClub = firestore.ordered.clubs.find(
      (each) => each.id === rowKeys[0]
    );
    if (selClub) {
      setSelectedRowKeys(rowKeys);
      dispatch(setSelectedClub(selClub));
      dispatch(setClubPlan(false));
      dispatch(setStripeAccountConnection(false));
      message.success(`${selClub.clubName} is selected`);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
    onChange: onSelectChange,
  };

  return (
    <Col xs={24} sm={24} md={18} lg={18}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={8} lg={8} className="px-4">
          <Content
            heading="Club Info"
            content="This is the information that your customers see."
          />
        </Col>
        <Col xs={24} sm={24} md={16} lg={16}>
          <div className="settings-club-info">
            <Form
              layout="vertical"
              form={form}
              name="advanced_searc"
              className="ant-advanced-search-form"
            >
              <Col xs={24} sm={24} md={13} lg={24} xlg={24}>
                <Card>
                  <Flex
                    className="py-2 font-weight-bold"
                    mobileFlex={false}
                    justifyContent="between"
                    alignItems="center"
                  >
                    <h2 className="mb-3">
                      {formMode === 'ADD' ? 'Add Club Info' : `Edit Club Info`}{' '}
                    </h2>
                    <div className="mb-3">
                      {formMode === 'EDIT' && (
                        <Button
                          className="mr-2"
                          onClick={() => {
                            setFormMode('VIEW');
                          }}
                        >
                          Cancel
                        </Button>
                      )}
                      <Button
                        type="primary"
                        loading={submitLoading || uploadLoading}
                        onClick={() => {
                          if (formMode === 'EDIT') {
                            onFinish();
                          } else {
                            setFormMode('EDIT');
                          }
                        }}
                      >
                        {formMode === 'EDIT' ? 'Save' : `Edit`}
                      </Button>
                    </div>
                  </Flex>
                  <Form.Item
                    name="clubName"
                    label="Name"
                    rules={formMode === 'EDIT' && rules.name}
                    validateTrigger="onBlur"
                  >
                    {formMode === 'EDIT' ? (
                      <Input placeholder="Name" />
                    ) : (
                      <InputViewForm
                        value={selectedClub && selectedClub.clubName}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    className=""
                    label="Color"
                    validateTrigger="onBlur"
                    rules={formMode === 'EDIT' && rules.color}
                  >
                    <div className="px-4">
                      {formMode === 'EDIT' ? (
                        <ColorPicker
                          selectedColor={data}
                          setSelectedColor={setData}
                        />
                      ) : (
                        <div className="swatch">
                          <div
                            className="color"
                            style={{
                              backgroundColor:
                                selectedClub && selectedClub.clubColor,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item
                    className="upload-img-sec"
                    validateTrigger="onBlur"
                    label="Logo"
                    rules={formMode === 'EDIT' && rules.logo}
                  >
                    {formMode === 'EDIT' ? (
                      <Uploadavatar
                        loading={loading}
                        data={data}
                        addPhoto={addPhoto}
                        setUploadLoading={setUploadLoading}
                      />
                    ) : (
                      <>
                        {selectedClub && selectedClub.clubLogo ? (
                          <img src={selectedClub.clubLogo} alt="logo" />
                        ) : (
                          <div className="logo-main-div">
                            <InputViewForm
                              value={selectedClub && selectedClub.clubLogo}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </Form.Item>
                </Card>{' '}
              </Col>
            </Form>
          </div>
        </Col>
        {/* </div> */}
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={8} lg={8} className="px-4">
          <Content
            heading="Select Club"
            content="If you have multiple clubs select change between them here. Or create a whole new club!"
          />
        </Col>
        <Col xs={24} sm={24} md={16} lg={16}>
          <div className="settings-club-info">
            <Form
              layout="vertical"
              form={form}
              name="advanced_search"
              className="ant-advanced-search-form"
            >
              <Col xs={24} sm={24} md={13} lg={24} xlg={24}>
                <Card title="">
                  <Flex
                    className="py-2 font-weight-bold"
                    mobileFlex={false}
                    justifyContent="between"
                    alignItems="center"
                  >
                    <h2 className="mb-3">Select Club</h2>
                  </Flex>
                  {loader ? (
                    <Loading />
                  ) : (
                    <Table
                      dataSource={club}
                      rowSelection={rowSelection}
                      rowKey="id"
                      pagination={false}
                    >
                      <Column title="Club Name" dataIndex="clubName" />
                    </Table>
                  )}
                  <div className="mt-3 text-right">
                    <Button
                      type="primary"
                      onClick={() => {
                        history.push('/create-new-club');
                      }}
                    >
                      Create New Club
                    </Button>
                  </div>
                </Card>
              </Col>
            </Form>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default withRouter(ClubInfo);
