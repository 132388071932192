import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import RightSide from '../../components/commonComponents/rightSide';
import LoginForm from '../../components/loginForm';
import WineIllustration from '../../assets/svg/wine.svg';
import './style.scss';

const Login = (props) => {
  const firebase = useSelector((state) => state.firebase);
  const { history } = props;
  useEffect(() => {
    if (!firebase.auth.isEmpty) {
      history.push('/select-club');
    }
    // eslint-disable-next-line
  }, [firebase]);
  return (
    <div className="login-page vh-100 bg-white">
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                <h1 className="font-weight-bold">Sign In</h1>
                <p>
                  Don&apos;t have an account yet?
                  <Link to="/sign-up"> Sign Up</Link>
                </p>
                <div className="mt-4">
                  <LoginForm />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <RightSide illustration={WineIllustration} />
      </Row>
    </div>
  );
};

export default withRouter(Login);
