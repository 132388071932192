import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import thunk from 'redux-thunk';
import { Reducers } from './reducers';
import firebase from '../config/firebase';

const store = createStore(Reducers, applyMiddleware(thunk));

// react-redux-firebase config
const rrfConfig = {
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

// react-redux-firebase props
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

function ReduxProvider({ children }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Provider store={store}>{children}</Provider>
    </ReactReduxFirebaseProvider>
  );
}

export default ReduxProvider;
