import { Row, Col } from 'antd';
import Logo from '../../../assets/images/Logo-PNG White-01.png';
import './style.scss';

const RightSide = (props) => {
  const { illustration } = props;
  return (
    <Col className="right-side" xs={0} sm={0} md={0} lg={8}>
      <div className="right-sec-bg d-flex flex-column justify-content-between h-100 px-4 pt-4">
        <div className="text-right">
          <img src={Logo} alt="logo" className="logo-height" />
        </div>
        <Row justify="center">
          <Col xs={0} sm={0} md={0} lg={20}>
            <img
              className="img-fluid mb-5"
              src={illustration}
              alt="illustration"
            />
            <h1 className="text-white">Welcome to Clinkee</h1>
            <p className="text-white">
              The premier service to create and manage the best wine clubs on
              the internet.
            </p>
          </Col>
        </Row>
        <div className="d-flex justify-content-end pb-4">
          <div>
            {/* <Link to="/" className="text-white" onClick={e => e.preventDefault()}>Term & Conditions</Link>
            <span className="mx-2 text-white"> | </span>
            <Link to="/" className="text-white" onClick={e => e.preventDefault()}>Privacy & Policy</Link> */}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default RightSide;
