import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Col, Row, Card } from 'antd';
import { useSelector } from 'react-redux';
import Flex from '../../../sharedComponents/flex';
import InputViewForm from '../../../sharedComponents/viewFormat';
import Content from './content';
import './style.scss';

const CustomDomain = () => {
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const [form] = Form.useForm();
  useEffect(() => {
    // if (formMode === 'EDIT') {
    if (selectedClub) {
      form.setFieldsValue({
        id: selectedClub.id,
        route: `https://payment.clinkee.com?clubId=${selectedClub.id}`,
      });
      // }
    }
  }, [form, selectedClub]);

  return (
    <Row gutter={16} className="club-info-container">
      <Col xs={24} sm={24} md={8} lg={8} className="px-4">
        <Content
          heading="Club URL"
          content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
        />
      </Col>
      <Col xs={24} sm={24} md={16} lg={16} className="settings-club-info">
        <Form
          layout="vertical"
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
        >
          <Col xs={24} sm={24} md={13} lg={24} xlg={24}>
            <Card>
              <Flex
                className="py-2 font-weight-bold"
                mobileFlex={false}
                justifyContent="between"
                alignItems="center"
              >
                <h2 className="mb-3">Club URL</h2>
              </Flex>
              <Form.Item
                name="route"
                // label="Club Id"
                validateTrigger="onBlur"
              >
                <InputViewForm value={selectedClub && selectedClub.route} />
              </Form.Item>
            </Card>{' '}
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default withRouter(CustomDomain);
