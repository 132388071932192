import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Row, Col, Button, Alert } from 'antd';
import { MotionDiv, Button as WinebarButton } from '@clinkee/winebar';
import { PlusOutlined } from '@ant-design/icons';
import { v1 as uuid } from 'uuid';
import WineIllustration from '../../assets/svg/undraw_having_fun_iais.svg';
import RightSide from '../../components/commonComponents/rightSide';
import Questions from '../../components/questions';
import { addCollections } from '../../utils/function';
import { setSelectedClub } from '../../store/actions/clubActions';
import { setClubPlan } from '../../store/actions/culbPlanAction';
import { setStripeAccountConnection } from '../../store/actions/stripeAccountConnection';

const NewClubQuestion = (props) => {
  const { data, setData, history } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const onSuccess = (successData) => {
    dispatch(setSelectedClub(successData));
    dispatch(setClubPlan(false));
    dispatch(setStripeAccountConnection(false));
    history.push('/');
  };

  const createClub = (withQuestions) => {
    const dubData = { ...data };
    if (!withQuestions) {
      dubData.questions = [];
    }
    if (dubData.questions && dubData.questions.find((sin) => !sin.question)) {
      setError('Question is required.');
    } else if (dubData.questions.find((sin) => sin.answers.length === 0)) {
      setError('Add atleast one answer');
    } else {
      setLoading(true);
      addCollections('clubs', data, onSuccess);
    }
  };

  return (
    <div className="login-page vh-100 bg-white">
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={14} xl={10}>
                <h1 className="font-weight-bold">Create Your New Club</h1>
                <p>
                  Let&apos;s add some questions so that you can better know the
                  individual taste&apos;s of your memebers
                </p>
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={20} sm={24} md={24} lg={20} xl={16}>
                {data && data.questions.length > 0 && (
                  <MotionDiv showMessage={error}>
                    <Alert type="error" showIcon message={error} />
                  </MotionDiv>
                )}
                <div className="mt-4">
                  <Questions
                    setError={setError}
                    setData={setData}
                    data={data}
                  />
                </div>
              </Col>
            </Row>
            <Row justify="center " className="mt-2">
              <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                <div className="d-flex justify-content-center my-2  ">
                  <Button
                    className="w-100"
                    type="dashed"
                    onClick={() => {
                      const dubData = { ...data };
                      const question = {
                        id: uuid(),
                        answers: [],
                        question: '',
                      };
                      if (dubData.questions.length === 0) {
                        dubData.questions.push(question);
                        setData({ ...dubData });
                      } else if (
                        dubData.questions.find((sin) => !sin.question)
                      ) {
                        setError('Question is required.');
                      } else if (
                        dubData.questions.find(
                          (sin) => sin.answers.length === 0
                        )
                      ) {
                        setError('Add atleast one answer');
                      } else {
                        dubData.questions.push(question);
                        setData(dubData);
                      }
                    }}
                  >
                    <PlusOutlined />
                    Add Question
                  </Button>
                </div>
                <WinebarButton
                  className="gradient-background mb-2"
                  onClick={() => {
                    createClub(true);
                  }}
                  buttonText="Create Club"
                  loading={loading}
                />
                <WinebarButton
                  onClick={() => {
                    createClub(false);
                  }}
                  buttonText="I'll do this later"
                  disabled={false}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <RightSide illustration={WineIllustration} />
      </Row>
    </div>
  );
};
export default NewClubQuestion;
