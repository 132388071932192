import { useState, useEffect } from 'react';
import { Grid } from 'antd';
import PageHeader from '../layoutComponents/pageHeader';
import SettingSideNav from './layoutSettings';
import utils from '../utils';

const { useBreakpoint } = Grid;

const Settings = () => {
  const [settingSideNav, setSettingSideNav] = useState();
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  useEffect(() => {
    if (!isMobile) {
      setSettingSideNav(true);
    } else {
      setSettingSideNav(false);
    }
  }, [isMobile]);
  const sideNavToggle = (status) => {
    if (isMobile) {
      setSettingSideNav(status);
    }
  };
  return (
    <>
      <PageHeader
        title="Settings"
        setSettingSideNav={setSettingSideNav}
        settingSideNav={settingSideNav}
        isMobile={isMobile}
      />
      <SettingSideNav
        sideNavToggle={sideNavToggle}
        settingSideNav={settingSideNav}
        isMobile={isMobile}
      />
    </>
  );
};

export default Settings;
