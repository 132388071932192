import { Route, Switch, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import assert from 'assert';
import ClubSelection from '../containers/ClubSelection';
import { setSelectedClub } from '../store/actions/clubActions';
import CreateNewClub from '../containers/CreateNewClub';
import Loader from '../components/loader';
import Dashboard from '../layouts/appLayout';

const PrivateRoutes = (props) => {
  const { history, location } = props;
  const firebase = useSelector((state) => state.firebase);
  const dispatch = useDispatch();
  const clubCollection = `clubs?where=adminId,array-contains,${firebase.auth.uid}`;
  useFirestoreConnect([
    {
      collection: 'clubs',
      where: [
        ['adminId', 'array-contains', firebase.auth.uid && firebase.auth.uid],
      ],
    },
  ]);
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const firestore = useSelector((state) => state.firestore);

  const compare = (obj1, obj2) => {
    try {
      assert.deepStrictEqual(obj1, obj2);
      return true;
    } catch (err) {
      return false;
    }
  };
  useEffect(() => {
    if (
      firestore.status.requested[clubCollection] &&
      !firestore.status.requesting[clubCollection]
    ) {
      if (
        !firestore.ordered.clubs ||
        (firestore.ordered.clubs && firestore.ordered.clubs.length === 0)
      ) {
        history.push('/create-new-club');
      } else if (!selectedClub && location.pathname !== '/create-new-club') {
        history.push('/select-club');
      } else if (selectedClub && firestore.ordered.clubs) {
        const club = firestore.ordered.clubs.find(
          (each) => each.id === selectedClub.id
        );
        if (!compare(club, selectedClub)) {
          dispatch(setSelectedClub(club));
        }
      }
    }
  }, [location.pathname, firestore.status]);
  return (
    <Switch>
      <Route path="/select-club" component={ClubSelection} />
      <Route path="/create-new-club" component={CreateNewClub} />
      <Route path="/" component={selectedClub ? Dashboard : Loader} />
    </Switch>
  );
};
export default withRouter(PrivateRoutes);
