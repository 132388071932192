import { Card, Button, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { connectedStripeRedirectedUrl } from '../../../config/config';
import Content from '../settingsInnerContent/content';

const StripePaymentMethod = () => {
  const selectedClub = useSelector((state) => state.club.selectedClub);

  return (
    <>
      <Col xs={24} sm={24} md={18} lg={18}>
        <Row
          className="settings-payment-provider"
          xs={24}
          sm={24}
          md={13}
          lg={24}
          xlg={24}
        >
          <Col xs={24} sm={24} md={8} lg={8} className="px-4">
            <Content
              heading="Payment Provider Info"
              content="Integrate a payment provider to automatically charge your customers for their monthly memberships to your club. "
            />
          </Col>
          <Col xs={24} sm={24} md={16} lg={16}>
            <Card className="checkout-form-container">
              <h2>Stripe Payment Integration</h2>
              <Button
                type="primary"
                htmlType="submit"
                className="w-100"
                disabled={!!selectedClub.connectedStripeUserId}
                onClick={() => {
                  window.location.href = connectedStripeRedirectedUrl;
                }}
              >
                {selectedClub.connectedStripeUserId
                  ? 'Connected With Stripe'
                  : 'Connect Stripe'}
              </Button>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default StripePaymentMethod;
