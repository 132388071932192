import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Grid } from 'antd';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from '../../constants/ThemeConstant';
import utils from '../utils';
import LogoImg from '../../assets/images/Logo-PNG-01.png';
import './style.scss';

const { useBreakpoint } = Grid;
const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return 'auto';
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  }
  return `${SIDE_NAV_WIDTH}px`;
};

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return 'd-none';
  }
  return 'logo';
};

const Logo = (props) => {
  const selectedClub = useSelector((state) => state.club.selectedClub);
  const { mobileLogo, navCollapsed } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');

  return (
    <div
      className={getLogoDisplay(isMobile, mobileLogo)}
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      {navCollapsed ? (
        <span className="mb-0 collapasedLogo">
          {selectedClub.clubName
            ? selectedClub.clubName.toUpperCase()[0]
            : 'logo'}
        </span>
      ) : (
        <img
          src={selectedClub.clubLogo ? selectedClub.clubLogo : LogoImg}
          alt={selectedClub.clubName ? selectedClub.clubName : 'logo'}
          className={`logo-height ${isMobile ? 'w-full' : ''}`}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(Logo);
