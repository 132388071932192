import { Form, Button } from '@clinkee/winebar';
import { useState } from 'react';
import firebase from 'firebase';
import { v1 as uuid } from 'uuid';
import Uploadavatar from './UploadLogo';

const UploadLogo = (props) => {
  const { next, data, setData } = props;
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState('allDone');
  const addPhoto = (files) => {
    setDisable('doLater');
    const { file } = files;
    const uid = uuid();
    const storage = firebase.storage();
    const imageRef = storage.ref('clubLogos').child(uid);
    const imgData = imageRef.put(file);
    imgData.on(
      'state_changed',
      () => {
        setLoading(true);
      },
      () => {
        setLoading(false);
      },
      () => {
        imageRef.getDownloadURL().then((downloadURL) => {
          const dubData = { ...data };
          dubData.clubLogo = downloadURL;
          setData(dubData);
          setLoading(false);
        });
      }
    );
  };

  return (
    <>
      <Form layout="vertical" name="upload-logo" onFinish={next}>
        <Uploadavatar
          loading={loading}
          setLoading={setLoading}
          data={data}
          setData={setData}
          setDisable={setDisable}
          addPhoto={addPhoto}
        />

        {!data.clubLogo && disable === 'allDone' ? (
          <Button
            className="gradient-background mt-4 mb-2"
            disabled
            loading={loading}
            buttonText="All Done"
          />
        ) : (
          <Button
            className="gradient-background mt-4 mb-2"
            htmlType="submit"
            loading={loading}
            buttonText="All Done"
          />
        )}
        <div />
        {disable === 'doLater' || data.clubLogo ? (
          <Button disabled loading={false} buttonText="I'll do this later" />
        ) : (
          <Button
            htmlType="submit"
            loading={false}
            buttonText="I'll do this later"
          />
        )}
      </Form>
    </>
  );
};
export default UploadLogo;
