import {
  Table,
  Menu,
  Card,
  Input,
  Modal,
  message,
  Tooltip,
  Button,
} from 'antd';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  SearchOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import EllipsisDropdown from '../../../sharedComponents/ellipsisDropdown';
import Flex from '../../../sharedComponents/flex';
import { editCollections, cloudFunction } from '../../../utils/function';
import AvatarStatus from '../../../sharedComponents/avatarStatus';
import utils from '../../utils';
import '../style.scss';

const { confirm } = Modal;
const ItemList = (props) => {
  const {
    shipment,
    setShipment,
    shipmentProductsBackup,
    setVisibleManageProduct,
    setVisible,
    shipmentId,
    setProductDetail,
  } = props;
  const [loading, setLoading] = useState(false);
  const onSearch = (e) => {
    const { value } = e.currentTarget;
    const dupShipment = { ...shipment };
    const dupShipmentProducts = [...shipmentProductsBackup];
    dupShipment.products = utils.wildCardSearch(dupShipmentProducts, value);
    setShipment(dupShipment);
  };

  const deleteInstance = (product) => {
    const objKey = 'id';
    const data = { ...shipment };
    data.products = utils.deleteArrayRow(shipment.products, objKey, product.id);
    editCollections('shipments', data);
    setShipment(data);
  };

  const deleteRow = (row) => {
    confirm({
      title: 'Do you Want to delete this Product from shipment?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        deleteInstance(row);
      },
    });
  };
  const moveUp = (row, ind) => {
    if (ind !== 0) {
      const data = { ...shipment };
      data.products[ind] = data.products[ind - 1];
      data.products[ind - 1] = row;
      setShipment(data);
      editCollections('shipments', data);
    }
  };
  const moveDown = (row, ind) => {
    if (ind !== shipment.products.length - 1) {
      const data = { ...shipment };
      data.products[ind] = data.products[ind + 1];
      data.products[ind + 1] = row;
      setShipment(data);
      editCollections('shipments', data);
    }
  };
  const updateOrders = () => {
    setLoading(true);
    const payLoad = {
      shipmentId,
    };
    cloudFunction('updateOrders', payLoad)
      .then(() => {
        setLoading(false);
        message.success(`Shipment orders updated successfully`);
      })
      .catch((err) => {
        setLoading(false);
        message.error(
          err && err.response && err.response.data && err.response.data.message
        );
      });
  };

  const dropdownMenu = (row, ind) => (
    <Menu>
      <Menu.Item onClick={() => moveUp(row, ind)}>
        <Flex alignItems="center">
          <UpCircleOutlined />
          <span className="ml-2">Move Up</span>
        </Flex>
      </Menu.Item>
      <Menu.Item onClick={() => moveDown(row, ind)}>
        <Flex alignItems="center">
          <DownCircleOutlined />
          <span className="ml-2">Move Down</span>
        </Flex>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setProductDetail(row);
          setVisible(true);
        }}
      >
        <Flex alignItems="center">
          <UnorderedListOutlined />
          <span className="ml-2">Preferences</span>
        </Flex>
      </Menu.Item>
      <Menu.Item onClick={() => deleteRow(row)}>
        <Flex alignItems="center">
          <DeleteOutlined />
          <span className="ml-2">Delete</span>
        </Flex>
      </Menu.Item>
    </Menu>
  );
  const tableColumns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      render: (productName, elm) => (
        <div className="mx-2">
          <div>
            <AvatarStatus
              size={60}
              type="square"
              src={elm.productImage ? elm.productImage : ''}
            />
          </div>
          <div className="font-weight-bold">{productName}</div>
        </div>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity) => <div>{quantity}</div>,
    },
    {
      title: '',
      dataIndex: 'Menu',
      key: 'action',
      render: (_, elm, ind) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm, ind)} />
        </div>
      ),
    },
  ];
  return (
    <Card>
      <div className="item-List">
        {/* header */}
        <div className="item-list-header">
          <h2>Item List</h2>
          <h3>
            {shipment && shipment.products && shipment.products.length
              ? shipment.products.length
              : 0}{' '}
            Items
          </h3>
        </div>
        {/* buttons */}
        <div className="item-list-button-container d-flex">
          <Button
            className="gradient-background w-100 mr-1"
            onClick={() => {
              setVisibleManageProduct(true);
            }}
          >
            Manage Items
          </Button>
          <Tooltip
            title="  We automatically help you decide which items are best to include in
            each user's order. Updating the orders will reallocate items
            for users that haven't been shipped or locked. if there for
            users that havent been shipped or locked. if there are no
            preferences or a tie between item prefrences, ones at the top of
            this list will be given highest priority."
          >
            <Button
              className="w-100 ml-1"
              loading={loading}
              onClick={() => updateOrders()}
            >
              Update Orders
            </Button>
          </Tooltip>
        </div>
        {/* table */}
        <div className="table-responsive">
          <div className="item-list-serch-continer">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              onChange={(e) => onSearch(e)}
            />
          </div>
          <Table
            columns={tableColumns}
            dataSource={shipment?.products || []}
            rowKey="id"
          />
        </div>
      </div>
    </Card>
  );
};
export default withRouter(ItemList);
