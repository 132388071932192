import React from 'react';
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

const EllipsisDropdown = ({ menu, placement }) => {
  return (
    <Dropdown overlay={menu} placement={placement} trigger={['click']}>
      <div className="ellipsis-dropdown">
        <EllipsisOutlined />
      </div>
    </Dropdown>
  );
};

EllipsisDropdown.defaultProps = {
  placement: 'bottomRight',
  menu: <Menu />,
};

export default EllipsisDropdown;
